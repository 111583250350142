import React, { useState } from 'react';

const OrderStatusDropdown =  ({ cName, selectedStatus, setSelectedStatus, setSelectedStatusLabel }) => {
  
  const handleQuantityChange = (event) => {
    setSelectedStatus(parseInt(event.target.value));
    setSelectedStatusLabel(event.target.options[event.target.selectedIndex].text); 
  };

  return (
    <select
      className={cName}
      value={selectedStatus}
      onChange={handleQuantityChange}
    >
      <option value={1}>Order Placed - Your order has been successfully submitted and confirmed.</option>
      <option value={2}>Preparing for Shipment - The seller is packing and preparing your order.</option>
      <option value={3}>Shipped - The order is in transit and on its way to you.</option>
      <option value={4}>Out for Delivery - The package is with the local carrier and will arrive soon.</option>
      <option value={5}>Delivered - Your order has been delivered to the specified address.</option>
    </select>
  );
};

export default OrderStatusDropdown;
