import React from 'react';

const QuantityDropdown = ({ cName, selectedQuantity, setSelectedQuantity }) => {
  
  const handleQuantityChange = (event) => {
    setSelectedQuantity(parseInt(event.target.value));
  };

  return (
    <select
      className={cName}
      value={selectedQuantity}
      onChange={handleQuantityChange}
    >
      {[...Array(20)].map((_, index) => (
        <option key={index + 1} value={index + 1}>
          {index + 1}
        </option>
      ))}
    </select>
  );
};

export default QuantityDropdown;
