import { userAuthenticated } from "../extension/currentUser";
import { url_path, retrieveAddress } from "./../extension/exportedStrings";

export const fetchAddress = async () => {
    const url = url_path + retrieveAddress;
    const data = {
      username: userAuthenticated,
    };
  
    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });
  
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
  
      const result = await response.json();
      return result[0].address || "Enter your address"; // Return the address or a default message
    } catch (error) {
      return "Enter your address"; // Return an error message if something goes wrong
    }
  };
  