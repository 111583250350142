import { deleteSelectedCartItems } from "../design/items/functions/cartFunctions";
import { userAuthenticated } from "../extension/currentUser";
import { addToNotification } from "./notificationData";
import { cartArray } from "./cartArray";
import { createPersonalDigital, updateDigitalStatus, updateStatus, url_path } from "../extension/exportedStrings";


export const updateOrderStatus = (unique_id) => {
  const url = url_path + updateStatus;
  const data = {
      unique_id: unique_id,
  };

  fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      if (!res.ok) {
        throw new Error(`HTTP error! Status: ${res.status}`);
      }
      return res.json();
    })
    .then((data) => {

    })
    .catch((error) => {

    });
};
export const addListToNotificationAndClearCart = (selectedCart) => {
  selectedCart.forEach(item => {
    addToNotification(item, userAuthenticated, 
      userAuthenticated + "has purchased your item named " + item.itemName + " to cart!"
    );
  });

  deleteSelectedCartItems(cartArray);
}

export const updateBuyOrderStatus = (unique_id) => {
  const url = url_path + updateStatus;
  const data = {
      unique_id: unique_id,
  };

  fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      if (!res.ok) {
        throw new Error(`HTTP error! Status: ${res.status}`);
      }
      return res.json();
    })
    .then((data) => {

    })
    .catch((error) => {

    });
};

export const insertBuyToNotification = (orders) => {
  addToNotification(orders[0], userAuthenticated, 
    userAuthenticated + " has purchased your item named " + orders[0].itemName + " to cart!"
  );
}

export const updateBuyDigitalStatus = (itemUrl) => {
  const url = url_path + updateDigitalStatus;
  const data = {
    itemUrl: itemUrl,
    usermame: userAuthenticated,
  };

  fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      if (!res.ok) {
        throw new Error(`HTTP error! Status: ${res.status}`);
      }
      return res.json();
    })
    .then((data) => {
        console.log(data)
    })
    .catch((error) => {
        console.log(error);
    });
};