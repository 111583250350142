import React, { useEffect, useState } from "react";
import { food_icon } from "./exportedIcons";
import { url_link } from "./exportedStrings";

export const CartImage = ({ timestring, currentPath, type }) => {
  const [imageSrc, setImageSrc] = useState(food_icon);

  useEffect(() => {
    const fetchImage = async () => {
      const value = timestring + currentPath;
      let imageSrc;
      if (type === "item") {
        imageSrc = url_link + "transaction/itemImg/" + value + "0.png";
      } else {
        imageSrc = url_link + "transaction/digitalImg/" + value + ".png";
      }

      try {
        const response = await fetch(imageSrc, { method: "HEAD" });

        if (response.status === 200) {
          setImageSrc(imageSrc); // Image exists, update the state with the image source
        } else {
          setImageSrc(food_icon); // Image does not exist, use the default icon
        }
      } catch (error) {
        setImageSrc(food_icon); // In case of an error, use the default icon
      }
    };

    fetchImage();
  }, [timestring, currentPath, type]);

  return <img src={imageSrc} className="checkoutIcon" alt={type} />;
};

export default CartImage;
