/** @format */

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import MinibarComponent from "./../design/Minibar.js";
import FooterComponent from "../design/Footer.js";

import { userAuthenticated } from "../extension/currentUser.js";
import { app_icon } from "../extension/exportedIcons.js";
import {
  url_path,
  insertAddress,
  copyrightLabel,
  retrievePersonalComment,
} from "../extension/exportedStrings.js";

import "./../style/rating-view.css";
import { fetchAddress } from "../data/retrieveAddress.js";
import { calculateAverageRating } from "../extension/calculateAverageRating.js";

const RatingView = () => {
  const navigate = useNavigate();

  const [comments, setComments] = useState([]);
  const [commentRating, setCommentRating] = useState("");

  const [isLoading, setIsLoading] = useState([true]);
  useEffect(() => {
    const retrievePersonalComments = () => {
      const url = url_path + retrievePersonalComment;
      const data = {
        username: userAuthenticated, // Replace with the actual username
      };

      fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      })
        .then((res) => {
          if (!res.ok) {
            throw new Error(`HTTP error! Status: ${res.status}`);
          }
          return res.json();
        })
        .then(async (data) => {
          const ratings = data.map((item) => parseFloat(item.rating));

          // Calculate the average rating
          const averageRating = calculateAverageRating(ratings);
          setCommentRating(averageRating);
          setComments(data.reverse());
          setIsLoading(false);
        })
        .catch((error) => {
          console.error("Error retrieving items:", error);
          setIsLoading(false);
        });
    };

    retrievePersonalComments();
  }, []);

  const goProfile = (value) => {
    let path = `/${value}`;
    navigate(path);
  };

  return (
    <div>
      <MinibarComponent />

      <div className="ratingViewBox">
        {isLoading ? (
          <div className="spinner-container">
            <div className="spinner"></div>
          </div>
        ) : comments == null || comments.length === 0 ? (
          <div className="ratingViewEmptyDiv">
            <p className="ratingViewEmpty">This listing has no ratings yet</p>
          </div>
        ) : (
          <>
            <div className="ratingViewHeaderDiv">
              <p className="ratingViewLeftLabel">Your Ratings & Reviews</p>
              <p className="ratingViewRightLabel">
                Sale Rating Avg {commentRating}
              </p>
            </div>

            <div className="ratingViewList">
              <table className="ratingViewTable">
                {comments.map((item, index) => (
                  <tr key={index}>
                    <td>
                      <div className="ratingViewLine" onClick={() => goProfile(item.username)}>
                        <p className="ratingViewUsername">{item.username} - {item.itemName}</p>
                        <p className="ratingViewRating">{item.rating} ★</p>
                      </div>
                      <p className="ratingViewAdditional">{item.comment}</p>
                    </td>
                  </tr>
                ))}
                </table>
            </div>
          </>
        )}
      </div>

      <div className="loginFooterDiv">
        <div className="footerView">
          <img className="footerImg" src={app_icon} alt="App icon" />
          <FooterComponent />
        </div>

        <p className="copyrightLabel">{copyrightLabel}</p>
      </div>
    </div>
  );
};

export default RatingView;
