export const genreArray = [
    "Default",
    "Alternative",
    "Blues",
    "Classical",
    "Country",
    "Electronic",
    "Hip Hop/Rap",
    "Jazz",
    "Pop",
    "R&B/Soul",
    "Reggae",
    "Rock",
    "World",
    "Other"
  ];
  