/** @format */

import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";

import {
  url_path,
  updateOrder,
  createSaleConfirmation,
} from "../extension/exportedStrings";
import "./../style/confirm-sale-dialog.css";
import OrderStatusDropdown from "../extension/orderStatusDropdown";
import {
  getStatusFromText,
  getTextFromStatus,
} from "../extension/orderStatusConfiguration";
import { openGallery } from "../extension/openGallery";
import { addImageToStorage } from "../firebase/imageFirebase";
import { generateUUID } from "../extension/generateUUID";

const ConfirmSaleDialog = ({ isOpen, setIsOpen, orderID }) => {
  const updateRef = useRef(null);
  const [currentImage, setCurrentImage] = useState(null);
  const [imageFile, setImageFile] = useState(null);

  const [textProofValue, setTextProofValue] = useState(""); // Initialize with trackingLabel

  const [buttonText, setButtonText] = useState("Confirm Proof");
  const handleInputChange = (event) => {
    setTextProofValue(event.target.value); // Update state with the new input value
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (updateRef.current && !updateRef.current.contains(event.target)) {
        closeDialog();
      }
    };

    const handleKeyDown = (event) => {
      if (event.key === "Escape") {
        closeDialog();
      }
    };

    const closeDialog = () => {
      setIsOpen(false);
      document.body.style.overflowY = "auto";
    };

    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
      document.removeEventListener("keydown", handleKeyDown);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [isOpen, setIsOpen, updateRef]);

  const uploadImageToFirebase = async () => {
    setButtonText("Processing...");

    const url = generateUUID();
    const downloadURL = await addImageToStorage(imageFile, url);
    createConfirmationOrder(downloadURL);
  };

  const createConfirmationOrder = (downloadURL) => {
    const url = url_path + createSaleConfirmation;
    const data = {
      orderID: orderID,
      imageProof: downloadURL,
      textProof: textProofValue,
    };

    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error(`HTTP error! Status: ${res.status}`);
        }
        return res.json();
      })
      .then((data) => {
        setButtonText("Confirm Proof");
        setIsOpen(false);
        window.location.reload();
      })
      .catch((error) => {
        setButtonText("Confirm Proof");
        setIsOpen(false);
        window.location.reload();
      });
  };

  return (
    <div>
      <div className={isOpen ? "darkenDiv" : "hidden"}>
        <div className="confirmSaleDialogBox" ref={updateRef}>
          <p className="confirmSaleLabel">Confirmation Details</p>
          <img className="confirmSaleImg" src={currentImage} />
          <button
            id="loginButton"
            className="confirmationSaleButton"
            onClick={() => {
              openGallery()
                .then((file) => {
                  const reader = new FileReader();
                  reader.readAsDataURL(file);
                  reader.onload = () => {
                    const imageDataUrl = reader.result;

                    setCurrentImage(imageDataUrl);
                    setImageFile(file);
                  };
                })
                .catch((error) => {
                  console.error("Error:", error);
                });
            }}
          >
            Upload delivery confirmation photo
          </button>
          <p className="confirmSaleLabel">Extra Confirmation Details</p>
          <input
            id="input2"
            className="confirmationSaleInput"
            type="text"
            name="username"
            placeholder="Enter additional details, e.g., a link or extra information."
            value={textProofValue}
            onChange={handleInputChange} // Track changes in the input
            onKeyDown={(event) => {
              if (event.keyCode === 13) uploadImageToFirebase(); // Pass the current input value when Enter is pressed
            }}
          />
          <button
            id="loginButton"
            className="updateButton"
            onClick={() => {
              if (buttonText !== "Confirm Proof") return;

              uploadImageToFirebase();
            }}
          >
            {buttonText}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmSaleDialog;
