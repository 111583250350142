/** @format */
import React from "react";

import "./../style/itempage-dialog.css";
import "./../style/item-page.css";

const CommentDialog = ({ itemComments, isOpen, setIsOpen }) => {
  const closeDialog = () => {
    setIsOpen(false);
    document.body.style.overflow = "auto";
  };

  return (
    <div
      className={isOpen ? "commentDarkenDiv" : "hidden"}
      onClick={closeDialog}
    >
      <p className="ratingName">Average ratings: {5} ★</p>
      <div className="ratingDiv">
        <div className="ratingLabelsDiv">
          <p className="ratingLeftDialogLabel">All comments</p>
          <p className="ratingRightDialogLabel" onClick={closeDialog}>
            Close comments
          </p>
        </div>
      </div>
      <div className="ratingTableWrapper">
        <table className="ratingTableDialog">
          {itemComments.map((item, index) => (
            <tr key={index}>
              <td>
                <div className="ratingLine">
                  <p className="buytableUsername">{item.username}</p>
                  <p className="buytableRating">{item.rating} ★</p>
                </div>
                <p className="buytableAdditional">{item.comment}</p>
              </td>
            </tr>
          ))}
        </table>
      </div>
    </div>
  );
};

export default CommentDialog;
