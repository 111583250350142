import { food_icon } from "./exportedIcons";
import { url_link } from "./exportedStrings";

export const fetchImages = async (timestring, currentUser, type) => {
  const value = timestring + currentUser.toLowerCase();
  const basePath = type === "item" ? "transaction/itemImg/" : "transaction/digitalImg/";
  let foundImages = [];
  let imageNumber = 0;

  if (type === "item") {
    // Fetch multiple images in a loop if type is "item"
    while (true) {
      const imageUrl = `${url_link}${basePath}${value}${imageNumber}.png`;
      try {
        const response = await fetch(imageUrl, { method: "HEAD" });
        if (response.status === 200) {
          foundImages.push(imageUrl);
        } else {
          break; // Exit the loop if the image is not found (status != 200)
        }
      } catch (error) {
        console.error("Error fetching item image:", error); // Improved error handling
        break; // Stop trying if an error occurs
      }
      imageNumber++; // Increment to check the next image
    }
  } else {
    // Fetch only one image for non-"item" type
    const imageUrl = `${url_link}${basePath}${value}.png`;
    try {
      const response = await fetch(imageUrl, { method: "HEAD" });
      if (response.status === 200) {
        foundImages.push(imageUrl);
      }
    } catch (error) {
      console.error("Error fetching digital image:", error); // Improved error handling
    }
  }

  // Return found images, or fallback to default icon if no images were found
  return foundImages.length > 0 ? foundImages : [food_icon];
};
