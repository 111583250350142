import React, { useEffect, useState, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import MinibarComponent from "./Minibar.js";
import FooterComponent from "./Footer.js";

import {
  app_icon,
  profile_icon,
  soci_coin,
} from "./../extension/exportedIcons";
import {
  copyrightLabel,
  delete_unread_count,
  messageList,
  retrieveMessage,
  sendMessage,
  url_link,
  url_path,
} from "./../extension/exportedStrings";

import "./../style/message.css";
import { generateStars } from "../extension/generateStars.js";
import { userAuthenticated } from "../extension/currentUser.js";
import { timeAgo } from "../extension/timeAgo.js";
import { generateUUID } from "../extension/generateUUID.js";

function Message() {
  const navigate = useNavigate();
  const location = useLocation();

  const [messageArray, setMessageArray] = useState([]);
  const [isMessageSelected, setIsMessageSelected] = useState(false);
  const [listArray, setListArray] = useState([]);

  const [currentPath, setCurrentPath] = useState(location.pathname.slice(1));

  const [indexSelected, setIndexSelected] = useState(-1);
  const [isLoading, setIsLoading] = useState(true);

  const [text, setText] = useState("");
  const [messagecount, setMessageCount] = useState(1);

  const messageListRef = useRef(null);

  useEffect(() => {
    const pathSegments = location.pathname.split("/");
    setCurrentPath(pathSegments[pathSegments.length - 1]);

    fetchMessage(pathSegments[pathSegments.length - 1]);
    fetchMessageList();
  }, [location]);

  useEffect(() => {
    if (messageListRef.current) {
      messageListRef.current.scrollTop = messageListRef.current.scrollHeight;
    }
  }, [messageArray]); // Runs whenever messageArray updates

  useEffect(() => {
    // Count the number of slashes in the path
    const slashCount = (location.pathname.match(/\//g) || []).length;
    
    // Set state based on the slash count
    if (slashCount === 2) {
      setIsMessageSelected(true);
    } else {
      setIsMessageSelected(false);
    }
  }, [location.pathname]);


  const insertMessageToDB = (messageModel) => {
    if (messageModel.message.trim() == "") return;

    const url = url_path + sendMessage;

    const data = {
      id: messageModel.chatID,
      message: messageModel.message.trim(),
      sender: userAuthenticated,
      receiver: currentPath,
      media: "",
      timestamp: Date.now(),
      messagecount: messagecount,
      last_message: messageModel.message.trim(),
      seen: false,
    };

    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error(`HTTP error! Status: ${res.status}`);
        }
        return res.json();
      })
      .then((data) => {
        const filteredData = data.filter((item) => !item.roomid);
        setListArray(filteredData);

        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Fetch error:", error);
        setIsLoading(false);
      });
  };

  const fetchMessage = (username) => {
    const url = url_path + retrieveMessage;

    const data = {
      sender: userAuthenticated, // Replace with the actual username
      receiver: username,
    };

    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error(`HTTP error! Status: ${res.status}`);
        }
        return res.json();
      })
      .then((data) => {
        setMessageArray(data);
      })
      .catch((error) => {
        console.error("Fetch error:", error);
      });
  };

  const fetchMessageList = () => {
    const url = url_path + messageList;

    const data = {
      username: userAuthenticated, // Replace with the actual username
    };

    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error(`HTTP error! Status: ${res.status}`);
        }
        return res.json();
      })
      .then((data) => {
        const filteredData = data.filter((item) => !item.roomid);

        setListArray(filteredData);

        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };

  const delete_unread = (sender, receiver) => {
    const url = url_path + delete_unread_count;

    const data = {
      sender: sender, // Replace with the actual username
      receiver: receiver,
    };

    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error(`HTTP error! Status: ${res.status}`);
        }
        return res.json();
      })
      .then((data) => {
        const filteredData = data.filter((item) => !item.roomid);
        setListArray(filteredData);

        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };

  const goProfile = (value) => {
    let path = `/${value}`;
    navigate(path);
  };

  const isIndexSelected = (index) => index === indexSelected;

  const handleSelect = (index, name) => {
    setMessageArray([]);
    fetchMessage(name);

    if (listArray[index].sender != userAuthenticated) {
      setMessageCount((listArray[index].message_count ?? 0) + 1);
      delete_unread(userAuthenticated, name);

      setListArray((prevItems) => {
        const updatedItems = [...prevItems];
        updatedItems[index] = {
          ...updatedItems[index],
          message_count: "0",
        };
        return updatedItems;
      });
    }

    setIndexSelected(index); // Set the selected index on click
    let path = `/message/${name}`;
    navigate(path);
  };

  const handleSendMessage = (message) => {
    const chatID = messageArray && messageArray.length > 0 
    ? messageArray[0].chatID 
    : generateUUID();

    console.log(messageArray);
    const newMessageObj = {
      chatID: chatID, // Assign or fetch a valid chat ID
      message: message,
      owner: userAuthenticated,
      sender: userAuthenticated,
      receiver: currentPath,
      created: Date.now(),
      media: "",
    };

    insertMessageToDB(newMessageObj);
    setMessageArray((prevMessages) => {
      // Check if prevMessages is null, undefined, or empty
      if (!Array.isArray(prevMessages) || prevMessages.length === 0) {
        return [newMessageObj]; // Initialize with the new message
      }
      // Prepend the new message to the existing array
      return [newMessageObj, ...prevMessages];
    });
  };

  const handleChange = (event) => {
    setText(event.target.value);
  };

  return (
    <div>
      <MinibarComponent />

      <div className="messageDiv">
        <div className="leftMessageDiv">
          {listArray.length === 0 ? (
            <div className="message-loading-container" id="itemContainer">
              {isLoading ? (
                <div className="spinner-container">
                  <div className="spinner"></div>
                </div>
              ) : (
                <p id="profileStatusText" className="profileStatusText">
                  You have no messages
                </p>
              )}
            </div>
          ) : (
            <div className="leftMessageContainer">
              {listArray.map((item, index) => (
                <>
                  <div
                    className={`${
                      index === 0 ? "messageListDivFirst" : "messageListDiv"
                    } ${indexSelected === index ? "message_selected" : ""}`}
                    key={index}
                    onClick={() => {
                      const username =
                        item.sender !== userAuthenticated
                          ? item.sender
                          : item.receiver;
                      handleSelect(index, username);
                    }}
                  >
                    <img
                      className="messageListIcon"
                      src={`${url_link}images/${item.username}.png`}
                      onError={(e) => {
                        if (e.target.src !== profile_icon) {
                          e.target.onerror = null;
                          e.target.src = profile_icon;
                        }
                      }}
                    ></img>
                    <div className="messageMainTextDiv">
                      <div className="messageListTextDiv">
                        <p className="messageListName">
                          {item.sender !== userAuthenticated
                            ? item.sender
                            : item.receiver}
                        </p>
                        <p className="messageListHobby">{item.last_message}</p>
                      </div>
                      <div className="messageListTextDiv2">
                        <p className="messageListCount">
                          {indexSelected === index ||
                          item.message_count === "0" ||
                          item.sender == userAuthenticated
                            ? "‎"
                            : item.message_count}
                        </p>
                        <p className="messageListTimeString">
                          {" "}
                          {timeAgo(item.last_sent)}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="message_line" />
                </>
              ))}
            </div>
          )}
        </div>

        <div className="rightMessageDiv">
          {isMessageSelected && (
            <>
              <div
                className="rightMessageToolbar"
                onClick={() => {
                  goProfile(currentPath);
                }}
              >
                <img
                  className="messageIcon"
                  src={`${url_link}images/${currentPath}.png`}
                  onError={(e) => {
                    if (e.target.src !== profile_icon) {
                      e.target.onerror = null;
                      e.target.src = profile_icon;
                    }
                  }}
                />
                <div className="messageToolbarTextDiv">
                  <p className="messageName">{currentPath}</p>
                  <p className="messageAdditional">{generateStars(5)}</p>
                </div>
              </div>
              <div className="message_line" />
              <div className="chat-container">
                <div className="message-list" ref={messageListRef}>
                  {messageArray && messageArray.length > 0 ? (
                    messageArray.map((message) => (
                      <div
                        key={message.id}
                        className={
                          message.sender !== userAuthenticated
                            ? "message-bubble-left"
                            : "message-bubble-right"
                        }
                      >
                        {message.message}
                      </div>
                    ))
                  ) : (
                    <div></div>
                  )}
                </div>

                <div className="message-input-container">
                  <input
                    type="text"
                    placeholder="Type a message..."
                    className="message-text-input"
                    value={text}
                    onChange={handleChange}
                  />
                  <button
                    className="message_button"
                    onClick={() => {
                      handleSendMessage(text);
                      setText("");
                    }}
                  >
                    Send
                  </button>
                </div>
              </div>
            </>
          )}
        </div>
      </div>

      <div className="loginFooterDiv">
        <div className="footerView">
          <img className="footerImg" src={app_icon} alt="Image" />
          <FooterComponent />
        </div>

        <p className="copyrightLabel2">{copyrightLabel}</p>
      </div>
    </div>
  );
}

export default Message;
