/** @format */

import React, { useEffect, useRef, useState } from "react";

import {
  url_path,
  retrieveSaleConfirmation,
} from "../extension/exportedStrings";
import "./../style/order-proof-dialog.css";

import FullScreenImageDialog from "./FullScreenImageDialog";
import AddRatingDialog from "./AddRatingDIalog";

const OrderProofDialog = ({ isOpen, setIsOpen, orderID, order }) => {
  const updateRef = useRef(null);
  const [currentImage, setCurrentImage] = useState(null);
  const [textProofValue, setTextProofValue] = useState(""); // Initialize with trackingLabel

  const [isLoading, setIsLoading] = useState([true]);
  const [isRatingOpen, setIsRatingOpen] = useState(false);
  const [isFullScreenOpen, setIsFullScreenOpen] = useState(false);

  const [buttonText, setButtonText] = useState("Done");

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (updateRef.current && !updateRef.current.contains(event.target)) {
        closeDialog();
      }
    };

    const handleKeyDown = (event) => {
      if (event.key === "Escape") {
        closeDialog();
      }
    };

    const closeDialog = () => {
      setIsOpen(false);
      document.body.style.overflowY = "auto";
    };

    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
      document.removeEventListener("keydown", handleKeyDown);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [isOpen, setIsOpen, updateRef]);

  useEffect(() => {
    const retrieveConfirmationOrder = () => {
      const url = url_path + retrieveSaleConfirmation;
      const data = {
        orderID: orderID,
      };

      fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      })
        .then((res) => {
          if (!res.ok) {
            throw new Error(`HTTP error! Status: ${res.status}`);
          }
          return res.json();
        })
        .then((data) => {
          setCurrentImage(data[0].imageProof);
          setTextProofValue(data[0].textProof);

          setIsLoading(false);
        })
        .catch((error) => {
          retrieveConfirmationOrder();
        });
    };

    retrieveConfirmationOrder();
  }, [orderID, url_path, retrieveSaleConfirmation]); // Add dependencies to avoid unnecessary re-renders

  const openFullScreen = () => {
    setIsFullScreenOpen(true);
    document.body.style.overflowY = "hidden";
  };

  const openRating = () => {
    setIsRatingOpen(true);
    document.body.style.overflowY = "hidden";
  };


  return (
    <div>
      <div className={isOpen ? "darkenDiv" : "hidden"}>
        <div className="confirmSaleDialogBox" ref={updateRef}>
          {isLoading ? (
            <div className="order-spinner-container">
              <div className="spinner"></div>
            </div>
          ) : (
            <>
              <p className="confirmSaleLabel">Photo Evidence</p>
              <img
                className="confirmSaleImg"
                src={currentImage}
                alt="Photo Evidence"
                onClick={openFullScreen}
              />
              <p className="confirmSaleLabel">Extra Confirmation Details</p>
              <div className="orderProofDiv">
                <p className="orderProofDialogLabel">{textProofValue}</p>
              </div>
              <button
                id="loginButton"
                className="confirmationSaleButton"
                onClick={() => {
                  const mailtoLink = `mailto:socirank@gmail.com?subject=Refund Request&body=Hello, I would like to inquire about the following order: No.${orderID}`;
                  window.open(mailtoLink, "_blank");
                }}
              >
                Report a problem
              </button>
              <button
                id="loginButton"
                className="updateButton"
                onClick={() => {
                  setIsOpen(false);
                  openRating();
                }}>
                Leave a review
              </button>
            </>
          )}
        </div>
      </div>

      <AddRatingDialog isOpen={isRatingOpen} setIsOpen={setIsRatingOpen} order={order}/>
      <FullScreenImageDialog isOpen={isFullScreenOpen} setIsOpen={setIsFullScreenOpen} currentImage={currentImage}/>
    </div>
  );
};

export default OrderProofDialog;
