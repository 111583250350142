import React, { useState } from "react";

import "./../style/itempage-dialog.css";

const ItemBuyDialog = ({imageArray, isOpen, setIsOpen}) => {
  
    const [currentImageIndex, setCurrentImageIndex] = useState(0);

    const setImage = (index) => {
      setCurrentImageIndex(index);
    };

    const handleArrowRightClick = () => {
      const nextIndex =
        currentImageIndex === imageArray.length - 1 ? 0 : currentImageIndex + 1;
      setCurrentImageIndex(nextIndex);
    };
  
    const handleArrowLeftClick = () => {
      const prevIndex =
        currentImageIndex === 0 ? imageArray.length - 1 : currentImageIndex - 1;
      setCurrentImageIndex(prevIndex);
    };

    const closeDialog = () => {
      setIsOpen(false);
      document.body.style.overflow = "auto";
    };
  
    return (
      <div>
        <div className={isOpen ? "darkenDiv" : "hidden"} onClick={closeDialog}>
            <img className="buyDialogImg" src={imageArray[currentImageIndex]}  onClick={(event) => {
                    event.stopPropagation(); // Prevent click event propagation
              }}>
            </img>

            <svg
                className="dialogArrowLeft"
                viewBox="0 0 16 16"
                onClick={(event) => {
                    event.stopPropagation(); // Prevent click event propagation
                    handleArrowLeftClick();
                }}>
                <path
                  fill="#5A5A5A"
                  d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0zm3.5 7.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5z"
                />
              </svg>
              <svg
                className="dialogArrowRight"
                viewBox="0 0 16 16"
                onClick={(event) => {
                    event.stopPropagation(); // Prevent click event propagation
                    handleArrowRightClick();
                }}>
                <path
                  fill="#5A5A5A"
                  d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z"
                />
              </svg>

              <svg class="dialogCross" viewBox="0 0 16 16" onClick={closeDialog}>
               <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z"/> 
               </svg>

              <div className="buy-dialog-container" id="gridContainer">
              {imageArray.map((image, index) => (
                <div
                  className={`dialogbuy-item2 ${
                    index === currentImageIndex ? "selectedItem" : ""
                  }`}
                  key={index}
                  onClick={(event) => {
                    event.stopPropagation();
                    setImage(index)
                    }}>
                  <img src={image} alt={`Image ${index + 1}`} />
                </div>
              ))}
            </div>

          </div>
      </div>
    );
  };
  
  export default ItemBuyDialog;
  