/** @format */

import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import QuantityDropdown from "./../extension/genreDropdown";
import MinibarComponent from "./../design/Minibar.js";
import FooterComponent from "./../design/Footer.js";

import { genreArray } from "./../data/genreArray";
import { uploadSingleItemImg } from "./../extension/uploadItemImg";

import { userAuthenticated } from "./../extension/currentUser";
import { convertSecondsToTime } from "./../extension/SecondsToTime";
import { isDurationLongerThanTenMinutes } from "./../extension/isDurationLongerThan.js";

import {
  getCurrentTimeInMilliseconds,
  uploadItemNames,
} from "./../extension/getMilliseconds";

import { addVideoToStorage } from './../firebase/videoFirebase.js';

import { generateUUID } from "./../extension/generateUUID";
import { openGallery } from "./../extension/openGallery";
import { openAudio } from "./../extension/openAudio";
import { app_icon } from "./../extension/exportedIcons";
import {
  url_path,
  createDigital,
  digitalImage,
  digitalAudio,
  digitalVideoToAudio,
  copyrightLabel,
} from "./../extension/exportedStrings";

import "./../style/profile_digital_goods.css";
import { calculateFees } from "../extension/feesRate.js";

function ProfileDigitalGoods() {
  const navigate = useNavigate();

  useEffect(() => {
    document.body.style.overflowY = "auto";
  }, []);

  const [selectedQuantity, setSelectedQuantity] = useState(1);
  const [imgsArray, setImgsArray] = useState([]);
  const [currentImage, setCurrentImage] = useState(null);

  const [musicTextName, setMusicTextName] = useState("No Music Selected");
  const [musicTextTime, setMusicTextTime] = useState("0:00");
  const [durationString, setDurationString] = useState("0:00");

  const [audioFile, setAudioFile] = useState("");
  const [isPlaying, setIsPlaying] = useState(false);

  const [buttonText, setButtonText] = useState("Publish");
  const [buttonEnabled, setButtonEnabled] = useState(true);

  const [audio, setAudio] = useState(new Audio());
  const [video, setVideo] = useState(null);

  const [price, setPrice] = useState(""); // Initialize state for price

  const handlePriceChange = (e) => {
    setPrice(e.target.value); // Update state with the input value
  };
  
  const submitDigital = async () => {
    if(isDurationLongerThanTenMinutes(durationString)){
      setButtonText("Audio file is too long");
      return;
    }else{
      setButtonText("Publish");
    }

    const middleImage = document.getElementById("addMiddleImage");
    const middleDiv = document.getElementById("addMusicDiv");
    const DigitalID = document.getElementById("addDigitalID");
    const DigitalProof = document.getElementById("addDigitalProof");
    const DigitalDescription = document.getElementById("addDigitalDescription");
    const DigitalCategory = document.getElementById("DigitalCategory");
    const DigitalPrice = document.getElementById("addDigitalPrice");

    if (currentImage == null) {
      middleImage.style.border = "1px solid #E30B5C";
      return;
    }
    middleImage.style.border = "1px solid #e3e3e3e3";

    if (audioFile == "") {
      middleDiv.style.border = "1px solid #E30B5C";
      return;
    }
    middleDiv.style.border = "1px solid #e3e3e3e3";


    if (DigitalProof.value.trim() == "") {
      DigitalProof.style.border = "1px solid #E30B5C";
      return;
    }
    DigitalProof.style.border = "1px solid #e3e3e3e3";

    if (DigitalID.value.trim() == "") {
      DigitalID.style.border = "1px solid #E30B5C";
      return;
    }
    DigitalID.style.border = "1px solid #e3e3e3e3";

    if (DigitalDescription.value.trim() == "") {
      DigitalDescription.style.border = "1px solid #E30B5C";
      return;
    }
    DigitalDescription.style.border = "1px solid #e3e3e3e3";

    if (DigitalPrice.value.trim() == "") {
      DigitalPrice.style.border = "1px solid #E30B5C";
      return;
    }
    DigitalPrice.style.border = "1px solid #e3e3e3e3";

    setButtonEnabled(false);
    setButtonText("Publishing...");

    const timestamp = getCurrentTimeInMilliseconds(); // Use const instead of let for immutable variables
    const name = DigitalID.value.trim();
    const description = DigitalDescription.value.trim();
    const url = generateUUID();
    const category = genreArray[selectedQuantity];
    const price = DigitalPrice.value.trim();
    const proof = DigitalProof.value.trim();
    const imageName = timestamp + userAuthenticated;

    uploadSingleItemImg(currentImage).then(resizedUrl => {
      uploadImageToBackend(resizedUrl, imageName);
    });

    if (audioFile.startsWith("data:video")) {
      const downloadURL = await addVideoToStorage(video, url);

      uploadVideoToBackend(downloadURL, imageName)
      .then((result) => {
        if (result === 'success') {
          uploadDigitalsToDatabase(name, description, url, category, price, proof, timestamp);
        }else{
          setButtonText("Try Again");
          setButtonEnabled(true);
        }
      })
      .catch((error) => {
        setButtonText("Try Again");
        setButtonEnabled(true);
      });
    }else{
      uploadAudioToBackend(audioFile, imageName).then((result) => {
        if (result === 'success') {
          uploadDigitalsToDatabase(name, description, url, category, price, proof, timestamp);
        }else{
          setButtonText("Try Again");
          setButtonEnabled(true);
        }
      })
      .catch((error) => {
        setButtonText("Try Again");
        setButtonEnabled(true);
      });
    }
  };

  const uploadVideoToBackend = async (video, name) => {
    return new Promise((resolve, reject) => {
      const url = url_path + digitalVideoToAudio;
  
      fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json", // Specify the content type
        },
        body: JSON.stringify({ video, name }), // Convert to JSON string
      })
        .then((res) => {
          if (!res.ok) {
            throw new Error(`HTTP error! Status: ${res.status}`);
          }
          return res.json();
        })
        .then((data) => {
          if (data === "success") {
            resolve('success');
          } else {
            reject('fail');
          }
        })
        .catch((error) => {
          // Handle any errors that occurred during the fetch
          console.error('Error uploading video:', error);
          reject('fail');
        });
    });
  };

  const uploadAudioToBackend = async (audio,name) => {
    return new Promise((resolve, reject) => {
      const url = url_path + digitalAudio;

      fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json", // Specify the content type
        },
        body: JSON.stringify({ audio, name }), // Convert to JSON string
      })
        .then((res) => {
          if (!res.ok) {
            throw new Error(`HTTP error! Status: ${res.status}`);
          }
          return res.json();
        })
        .then((data) => {
            if(data == "success"){
              resolve('success');
            }else{
              reject('fail');
            }
        })
        .catch((error) => {
          // Handle any errors that occurred during the fetch
          reject('fail');
        });
    });
  };

  const uploadImageToBackend = async (imageArray,nameArray) => {
    const url = url_path + digitalImage;

    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json", // Specify the content type
      },
      body: JSON.stringify({ imageArray, nameArray }), // Convert to JSON string
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error(`HTTP error! Status: ${res.status}`);
        }
        return res.json();
      })
      .then((data) => {
      })
      .catch((error) => {
        // Handle any errors that occurred during the fetch
        console.log(error);
        setButtonText("Try Again");
        setButtonEnabled(true);
      });
  };

  const uploadDigitalsToDatabase = async (
    DigitalName,
    DigitalDescription,
    DigitalUrl,
    DigitalCategory,
    DigitalPrice,
    DigitalProof,
    DigitalTimeString
  ) => {
    const url = url_path + createDigital;
    const data = {
      username: userAuthenticated, // Replace with the actual username
      DigitalName: DigitalName, // Replace with the actual password
      DigitalDescription: DigitalDescription,
      DigitalUrl: DigitalUrl,
      DigitalCategory: DigitalCategory,
      DigitalPrice: DigitalPrice,
      DigitalRating: "0",
      DigitalProof: DigitalProof,
      timestring: DigitalTimeString,
    };

    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error(`HTTP error! Status: ${res.status}`);
        }
        return res.json();
      })
      .then((data) => {
        let path = `/${userAuthenticated}`;
        navigate(path);
      })
      .catch((error) => {
        // Handle any errors that occurred during the fetch
        setButtonText("Try Again");
        setButtonEnabled(true);
      });
  };

  const playAudio = () => {
    if (isPlaying) {
      setIsPlaying(false);
      audio.pause();
    } else {
      setIsPlaying(true);

      audio.play();
    }
  };

  audio.addEventListener("timeupdate", () => {
    // Get the time remaining by subtracting the current time from the total duration
    const timeRemaining = audio.duration - audio.currentTime;

    // Update the time remaining in the format mm:ss
    setMusicTextTime(convertSecondsToTime(timeRemaining));
  });

  audio.addEventListener("ended", () => {
    // Audio has finished playing, you can perform any action here
    setMusicTextTime(durationString);
    setIsPlaying(false);
  });
  

  return (
    <div>
      <MinibarComponent />

      <div className="addDigitalDiv">
        <div className="addLeftDiv">
          <div className="addImageDiv">
            <img
              id="addMiddleImage"
              className="addDigitalMiddleImage"
              src={currentImage}
            />
            <div id="addMusicDiv" className="addMusicDiv">
              <div className="textMusicDiv">
                <p className="textMusicLabel">{musicTextName}</p>
                <p className="textMusicLabel2">{musicTextTime}</p>
              </div>
              <svg
                class="addMusicRightIcon"
                viewBox="0 0 16 16"
                onClick={playAudio}
              >
                {!isPlaying ? (
                  <path d="m11.596 8.697-6.363 3.692c-.54.313-1.233-.066-1.233-.697V4.308c0-.63.692-1.01 1.233-.696l6.363 3.692a.802.802 0 0 1 0 1.393z" />
                ) : (
                  <path d="M5.5 3.5A1.5 1.5 0 0 1 7 5v6a1.5 1.5 0 0 1-3 0V5a1.5 1.5 0 0 1 1.5-1.5zm5 0A1.5 1.5 0 0 1 12 5v6a1.5 1.5 0 0 1-3 0V5a1.5 1.5 0 0 1 1.5-1.5z" />
                )}
              </svg>
            </div>
          </div>
          <div className="add-Digital-container">
            <svg
              className="addDigitalicon"
              viewBox="0 0 448 512"
              onClick={() => {
                openGallery()
                  .then((file) => {
                    const reader = new FileReader();
                    reader.readAsDataURL(file);
                    reader.onload = () => {
                      const imageDataUrl = reader.result;
                      // Resolve the promise with the image data URL
                      setCurrentImage(imageDataUrl);
                    };
                  })
                  .catch((error) => {
                    console.error("Error:", error);
                  });
              }}
            >
              <path d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z" />
            </svg>
            <svg
              className="addDigitalicon"
              viewBox="0 0 16 16"
              onClick={() => {
                openAudio()
                  .then((file) => {
                    const reader = new FileReader();
                    reader.readAsDataURL(file);
                    reader.onload = () => {
                      setVideo(file);

                      const audioDataUrl = reader.result;
      
                      setMusicTextName(file.name);
                      setAudioFile(audioDataUrl);
                      setIsPlaying(false);

                      const storeAudio = new Audio();
                      storeAudio.src = audioDataUrl;
                      storeAudio.addEventListener("loadedmetadata", () => {
                        // Get the duration of the audio file
                        setMusicTextTime(convertSecondsToTime(storeAudio.duration));
                        setDurationString(convertSecondsToTime(storeAudio.duration));
                      });
                      setAudio(storeAudio);
                    };
                  })
                  .catch((error) => {
                    console.error("Error:", error);
                  });
              }}
            >
              <path d="M6 13c0 1.105-1.12 2-2.5 2S1 14.105 1 13c0-1.104 1.12-2 2.5-2s2.5.896 2.5 2zm9-2c0 1.105-1.12 2-2.5 2s-2.5-.895-2.5-2 1.12-2 2.5-2 2.5.895 2.5 2z" />{" "}
              <path fill-rule="evenodd" d="M14 11V2h1v9h-1zM6 3v10H5V3h1z" />{" "}
              <path d="M5 2.905a1 1 0 0 1 .9-.995l8-.8a1 1 0 0 1 1.1.995V3L5 4V2.905z" />{" "}
            </svg>
          </div>
          <div className="addLeftDigital">
            <p className="addDigitalLabel">Proof Of Ownership</p>
            <textarea
              id="addDigitalProof"
              className="addDigitalDescription"
              placeholder="Provide ownership details and images using URLs or alternative methods."
              autoFocus
            ></textarea>
          </div>
        </div>
        <div className="addRightDiv">
          <div className="addRightDigital">
            <p className="addDigitalLabel">Digital Name</p>
            <input
              id="addDigitalID"
              className="addDigitalInput"
              placeholder="Enter Digital name"
              autoFocus
            ></input>
          </div>
          <div className="addRightDigital">
            <p className="addDigitalLabel">Digital Description</p>
            <textarea
              id="addDigitalDescription"
              className="addDigitalDescription"
              placeholder="Enter Digital description"
              autoFocus
            ></textarea>
          </div>

          <div className="addRightDigital">
            <p className="addDigitalLabel">Category</p>
            <QuantityDropdown
              id={"DigitalCategory"}
              cName={"DigitalQuantity"}
              selectedQuantity={selectedQuantity}
              setSelectedQuantity={setSelectedQuantity}
            />
          </div>
          <div className="addRightDigital">
            <p className="addDigitalLabel">Set Price (USD)</p>
            <input
              id={"addDigitalPrice"}
              className="addDigitalInput"
              type="number"
              placeholder="Enter amount (e.g., 5000)"
              value={price} // Bind the state to the input's value
              onChange={handlePriceChange} // Handle changes
              autoFocus
            ></input>
          </div>
          <p className="feesLabel">{calculateFees(price)}</p>
          <button
            className="DigitalButton"
            onClick={submitDigital}
            disabled={!buttonEnabled}
          >
            {buttonText}
          </button>
        </div>
      </div>

      <div className="itemdigitalFooterDiv">
        <div className="footerView">
          <img className="footerImg" src={app_icon} alt="Image" />
          <FooterComponent />
        </div>
        <p className="copyrightLabel2">{copyrightLabel}</p>
      </div>
    </div>
  );
}

export default ProfileDigitalGoods;
