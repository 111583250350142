/** @format */
import React, { useEffect, useState, useRef } from "react";

import "./../style/add-rating-dialog.css";
import { url_path, insertComment } from "../extension/exportedStrings";
import { userAuthenticated } from "../extension/currentUser";

const AddRatingDialog = ({ isOpen, setIsOpen, order }) => {
  const divRef = useRef(null);

  const [comment, setComment] = useState(""); // Initialize with trackingLabel
  const [rating, setRating] = useState(""); // Initialize with trackingLabel
  const [hoveredRating, setHoveredRating] = useState(0); // Temporary state for hover

  const [buttonText, setButtonText] = useState("Confirm");
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (divRef.current && !divRef.current.contains(event.target)) {
        closeDialog();
      }
    };

    const handleKeyDown = (event) => {
      if (event.key === "Escape") {
        closeDialog();
      }
    };

    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
      document.addEventListener("keydown", handleKeyDown);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [isOpen]);

  const closeDialog = () => {
    setComment("");
    setRating("");
    setHoveredRating(0);
    setButtonText("Confirm");

    setIsOpen(false);
    document.body.style.overflowY = "auto";
  };

  const addRating = () => {
    if(rating == ""){
      setButtonText("Please provide a star rating.")
    }

    if(comment == ""){
      setButtonText("Please leave a comment")
    }
    if (rating == "" || comment == "") return;

    setButtonText("Sending...");
    const url = url_path + insertComment;
    const data = {
      username: userAuthenticated,
      itemUrl: order.order_id,
      comment: comment,
      rating: rating,
      timestring: Date.now(),
      saleBy: order.username,
      itemName: order.itemName,
    };

    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error(`HTTP error! Status: ${res.status}`);
        }
        return res.json();
      })
      .then((data) => {
        closeDialog();
      })
      .catch((error) => {
        closeDialog();
      });
  };

  const renderStars = () => {
    return [1, 2, 3, 4, 5].map((star) => (
      <span
        key={star}
        className={`addStar ${
          star <= (hoveredRating || rating) ? "selected" : ""
        }`}
        onClick={() => setRating(star)}
        onMouseEnter={() => {
          setHoveredRating(star);
        }}
        onMouseLeave={() => {
          setHoveredRating(0);
        }}
      >
        ★
      </span>
    ));
  };

  return (
    <div className={isOpen ? "darkenDiv" : "hidden"}>
      <div className={isOpen ? "addRatingDialogDiv" : "hidden"} ref={divRef}>
        <div className="addStarsContainer">{renderStars()}</div>
        <textarea
          className="addRatingInput"
          placeholder="Write your comment here..."
          value={comment}
          onChange={(e) => setComment(e.target.value)}
          rows="1"
          onInput={(e) => {
            e.target.style.height = "auto"; // Reset height to calculate the new height
            e.target.style.height = `${e.target.scrollHeight}px`; // Set the height to match content
          }}
        />
        <button
          className="addConfirmButton"
          onClick={() => {
            if (buttonText === "Sending...") return;
            addRating();
          }}>
          {buttonText}
        </button>
      </div>
    </div>
  );
};

export default AddRatingDialog;
