import Resizer from 'react-image-file-resizer';

export const uploadItemImg = (imageUrls) => {
  return new Promise((resolve, reject) => {
    const resizedImageUrls = [];

    // Iterate over each image URL
    imageUrls.forEach((imageUrl, index) => {
      // Convert the image URL to a Blob object
      fetch(imageUrl)
        .then((response) => response.blob())
        .then((blob) => {
          // Resize and compress the Blob object
          Resizer.imageFileResizer(
            blob,
            600, // Increased width for medium size
            600, // Increased height for medium size
            'JPEG', // Output format
            90, // Increased compression quality for better quality
            0, // Rotation
            (resizedFile) => {
              // Convert Blob to URL object
              const reader = new FileReader();
              reader.onload = () => {
                // Resolve the promise with the data URL
                resizedImageUrls[index] = reader.result;
                if (resizedImageUrls.length === imageUrls.length) {
                  // Check if all images have been resized
                  resolve(resizedImageUrls);
                }
              };
              reader.readAsDataURL(resizedFile);
            },
            'blob' // Output type (base64 or blob)
          );
        })
        .catch((error) => {
          console.error('Error fetching or converting image:', error);
          reject(error);
        });
    });
  });
};

export const uploadSingleItemImg = (imageUrl) => {
  return new Promise((resolve, reject) => {
    // Convert the image URL to a Blob object
    fetch(imageUrl)
      .then((response) => response.blob())
      .then((blob) => {
        // Resize and compress the Blob object
        Resizer.imageFileResizer(
          blob,
          600, // Increased width for medium size
          600, // Increased height for medium size
          'JPEG', // Output format
          90, // Increased compression quality for better quality
          0, // Rotation
          (resizedFile) => {
            // Convert Blob to URL object
            const reader = new FileReader();
            reader.onload = () => {
              // Resolve the promise with the data URL
              resolve(reader.result);
            };
            reader.readAsDataURL(resizedFile);
          },
          'blob' // Output type (base64 or blob)
        );
      })
      .catch((error) => {
        console.error('Error fetching or converting image:', error);
        reject(error);
      });
  });
};
