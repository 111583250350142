export const getStatusFromText = (text) => {
    switch (text) {
      case "Order Placed - Your order has been successfully submitted and confirmed.":
        return 1;
      case "Preparing for Shipment - The seller is packing and preparing your order.":
        return 2;
      case "Shipped - The order is in transit and on its way to you.":
        return 3;
      case "Out for Delivery - The package is with the local carrier and will arrive soon.":
        return 4;
      case "Delivered - Your order has been delivered to the specified address.":
        return 5;
      default:
        return 1; // Default to "Delivered" with value 1
    }
  };

  export const getTextFromStatus = (status) => {
    switch (status) {
      case 1:
        return "Order Placed - Your order has been successfully submitted and confirmed.";
      case 2:
        return "Preparing for Shipment - The seller is packing and preparing your order.";
      case 3:
        return "Shipped - The order is in transit and on its way to you.";
      case 4:
        return "Out for Delivery - The package is with the local carrier and will arrive soon.";
      case 5:
        return "Delivered - Your order has been delivered to the specified address.";
      default:
        return "Order Placed - Your order has been successfully submitted and confirmed."; // Default to "Order Placed"
    }
  };