/** @format */
import React, { useEffect, useState } from "react";
import { paypal_icon, soci_coin } from "../extension/exportedIcons";

import "./../style/balance-settings.css";

const BalanceSetting = ({ email }) => {
  const [withDrawText, setWidthdrawText] = useState(
    "You have not added a withdraw option"
  );

  useEffect(() => {
    if (email.trim() !== "") {
      const value = `Withdraw to ${email}`;
      setWidthdrawText(value);
    }
  }, [email]); // Make sure to include email as a dependency if it changes

  return (
    <div>
      <div className="paymentSettingsDiv">
        <p className="balanceSettingLabel">Available Balance</p>
        <button
          id="gSignInWrapper"
          className="balanceDollarButton"
          value="submit"
          style={{ backgroundColor: "#e3e3e3" }}
        >
          $0.00
        </button>
        <button
          id="gSignInWrapper"
          className="balanceDollarButton"
          value="submit"
          style={{ backgroundColor: "#CCCCFF" }}
        >
          <img
            src={soci_coin}
            className="paymentChoiceIcon"
            alt="Google Icon"
          />

          0.00
        </button>

        <button
          id="gSignInWrapper"
          className="paymentChoiceButton"
          value="submit"
          style={{ backgroundColor: "#0096FF" }}
        >
          <img
            src={paypal_icon}
            className="paymentChoiceIcon"
            alt="Google Icon"
          />
          {withDrawText}
        </button>

        <p className="paymentErrorLabel">Please ensure that the withdrawal details are accurate to prevent any issues with the transaction</p>
      </div>
    </div>
  );
};

export default BalanceSetting;
