/** @format */

import React, { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import MinibarComponent from "./Minibar.js";
import FooterComponent from "./Footer.js";

import { manageArray } from "./../data/manageArray";
import { app_icon, order_icon } from "./../extension/exportedIcons";
import { copyrightLabel } from "./../extension/exportedStrings";

import "./../style/manage.css";

function Manage() {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {}, []);

  const goToManage = (path) => {
    navigate(path);
  };
  return (
    <div>
      <MinibarComponent />
      <div className="manageDiv">
        <div className="manageGrid">
          {manageArray.map((item, index) => (
            <div className="manageItem" key={index} onClick={() => goToManage(item.path)}>
              <img className="manageIcon" src={item.image}/>
              <div className="manageTextDiv">
                <p className="manageLabel">{item.name}</p>
                <p className="manageLabel2">{item.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="loginFooterDiv">
        <div className="footerView">
          <img className="footerImg" src={app_icon} alt="Image" />
          <FooterComponent />
        </div>

        <p className="copyrightLabel">{copyrightLabel}</p>
      </div>
    </div>
  );
}

export default Manage;
