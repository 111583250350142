/** @format */
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import { soci_coin, add_item_icon, add_digital_icon } from "./../extension/exportedIcons";
import "./../style/buy-choice-dialog.css";
import { getBalance } from "../extension/currentUser";

import { calculateSociCoin } from "../extension/socicoin_calculation";
import { formatMoney } from "../extension/moneyConvert";

const BuyChoiceDialog = ({ isOpen, setIsOpen, price, itemData }) => {
  const divRef = useRef(null);
  const navigate = useNavigate();

  const balance = getBalance();
  const [isAffordable, setIsAffordable] = useState(false);

  const [isCardOpen, setIsCardOpen] = useState(false);

  const [convertedPrice, setConvertedprice] = useState(0);
  const [convertedSociCoin, setConvertedSociCoin] = useState(0);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (divRef.current && !divRef.current.contains(event.target)) {
        closeDialog();
      }
    };

    const handleKeyDown = (event) => {
      if (event.key === "Escape") {
        closeDialog();
      }
    };
    
    const canAfford = () => {
      setIsAffordable(convertedSociCoin <= balance);
    };

    const convertCoins = () => {
      setConvertedprice(formatMoney(price));
      setConvertedSociCoin(calculateSociCoin(price));
    };

    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
      document.addEventListener("keydown", handleKeyDown);
    }

    canAfford();
    convertCoins();

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [isOpen]);

  const closeDialog = () => {
    setIsOpen(false);
    document.body.style.overflowY = "auto";
  };

  return (
    <div className={isOpen ? "darkenDiv" : "hidden"}>
      <div className={isOpen ? "buyChoiceDialogDiv" : "hidden"} ref={divRef}>
      <div className="buyChoiceLabelDiv">
        <p className="buyChoiceLabel">Purchase for ${convertedPrice}</p>
        <p className="buyChoiceLabelOr">or</p>
        <img className="buyIconSociCoinChoice" src={soci_coin} alt="Image" />
        <p className="buyChoiceLabel">{convertedSociCoin}</p>
      </div>
      <p className="orChoice">Purchase With SociCoins</p>
      <button 
        className={isAffordable ? "choiceCoinButton" : "choiceDisableButton"} 
        onClick={() => {
          if (!isAffordable) return;
          closeDialog();
          itemData(2);
         }}>
        {balance} SociCoins
      </button>

      <p className="orChoice">Or</p>
      <button className="choiceCashButton" onClick={() => {
          closeDialog();
          itemData(1)
        }}>
        Pay With Card
      </button>
      </div>
    </div>
  );
};

export default BuyChoiceDialog;
