/** @format */
import { PaymentElement, LinkAuthenticationElement} from "@stripe/react-stripe-js";
import { useState } from "react";
import { useStripe, useElements } from "@stripe/react-stripe-js";

const StripePayment = ({ setSelected, clientSecret }) => {
  const [message, setMessage] = useState(`Save card`);

  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      return;
    }

    // const { token, error } = await stripe.createToken(elements.getElement(PaymentElement));

    const { error } = await stripe.confirmSetup({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url: `http://localhost:3000`,
      },
    });

    if (error.type === "card_error" || error.type === "validation_error") {
      setMessage(error.message);
    } else {
      console.log(error)
    }

    closeDialog();
  };

  const closeDialog = () => {
    setSelected("false");
  };

  return (
    <div>
      <form className="payment-form" onSubmit={handleSubmit}>
        <p className="paymentLabel">Save card information</p>
        <PaymentElement className="payment-element" />
        <button className="submit-button" type="submit">
          {message}
        </button>
      </form>
    </div>
  );
};

export default StripePayment;
