/** @format */
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { app_icon, app_icon2, soci_coin } from "./../extension/exportedIcons";
import { copyrightLabel } from "./../extension/exportedStrings";

import "./../style/TermsOfService.css";
import FooterComponent from "../design/Footer";

const SociCoinInstruction = () => {
  const navigate = useNavigate();

  useEffect(() => {
    document.title = "SociRank - SociCoin"; // Change the title here
  }, []);

  const goHome = () => {
    let path = `/`;
    navigate(path);
  };

  return (
    <div>
      <div className="tosBackground">
        <div className="tosHeader">
          <img src={soci_coin} className="SociCoinImage" width="25" height="25" onClick={goHome} />
          <p className="tosHeaderLabel">SociCoin Instructions</p>
        </div>

        <div className="tosDiv">
          <p className="tosDivLabel">SociCoin</p>
          <p className="tosDivBody">
            SociCoin is the exclusive digital currency of SociRank, designed to
            empower seamless transactions within our vibrant community. With
            SociCoin, users can buy and sell items, including unique digital
            creations, directly from other members. This currency isn't just for
            spending—it’s for earning too! Engage actively in the SociRank
            mobile app through meaningful interactions, and watch your SociCoin
            balance grow. Whether you're purchasing exclusive content or trading
            with fellow users, SociCoin is the key to unlocking a dynamic and
            rewarding social marketplace.
          </p>
          <p className="tosDivLabel">How to increase SociCoins</p>
          <p className="tosDivBody">
            Boost your SociCoins by actively engaging within the app, or
            conveniently purchase them through our website. You can earn
            SociCoins every time your video gets 1,000 likes, turning your
            talent into rewards. Plus, there’s an added incentive with the Daily
            Performance Bonus: if your video ranks in the Top 3 on the
            leaderboard for the day, you’ll receive a special bonus—500
            SociCoins for first place, 200 SociCoins for second, and 50
            SociCoins for third. Unleash your creativity, rise to the top, and
            start earning SociCoins today!
          </p>
          <p className="tosDivLabel">Convert SociCoins into real cash</p>
          <p className="tosDivBody">
            Your SociCoins aren't just for in-app purchases – they can also be
            converted into real cash! For every 1,000 SociCoins, you'll unlock
            $1 in your pocket. So, the more you engage, the more you earn, and
            the better the rewards! It's your talent and interaction, now paying
            off in both virtual and real-world value. And the best part? With
            50,000 SociCoins, you'll pocket a $50 reward. So start collecting,
            start earning, and watch your SociCoins transform into cash today!{" "}
          </p>
        </div>
      </div>
      <div className="tosFooterDiv">
        <div className="footerView">
          <img className="footerImg" src={app_icon} alt="Image" />
          <FooterComponent />
        </div>

        <p className="copyrightLabel2">{copyrightLabel}</p>
      </div>
    </div>
  );
};

export default SociCoinInstruction;
