/** @format */
import React, { useEffect, useState, } from "react";
import { useNavigate } from "react-router-dom";

import { checkProfileExist } from "./../extension/setProfileIcon";
import { userAuthenticated } from "./../extension/currentUser";

import {
  url_path,
  config,
  retrieveItemDigital,
  url_link,
} from "./../extension/exportedStrings";

import { digital_icon, profile_icon } from "./../extension/exportedIcons";
import "./../style/personal-collection.css";

const PersonalCollection = ({}) => {
  const navigate = useNavigate();

  const [profilePic, setProfilePic] = useState(profile_icon);

  const [items, setItems] = useState([]);
  const [isLoading, setIsLoading] = useState([true]);

  useEffect(() => {
    async function fetchProfileImage() {
      const imageSource = await checkProfileExist(userAuthenticated);
      setProfilePic(imageSource);
    }

    fetchProfileImage();
  }, []);

  useEffect(() => {
    const configDigital = () => {
      const url = url_path + retrieveItemDigital;
      const data = {
        username: userAuthenticated, // Replace with the actual username
      };

      fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      })
        .then((res) => {
          if (!res.ok) {
            throw new Error(`HTTP error! Status: ${res.status}`);
          }
          return res.json();
        })
        .then((data) => {
          console.log(data);
          setItems(data);
          setIsLoading(false);
        })
        .catch((error) => {
          setIsLoading(false);
          console.log(error);
        });
    };

    configDigital();
  }, []);

  const goToDigital = (itemUrl) => {
    const path = `/digital/${itemUrl}`;
    navigate(path);
  };

  return (
    <div>
      {items.length === 0 ? (
        <div className="personal-collection-container" id="itemContainer">
          {isLoading ? (
            <div className="spinner-container">
              <div className="spinner"></div>
            </div>
          ) : (
            <p>You have no digital collections</p>
          )}
        </div>
      ) : (
        <div className="collectionManageDiv">
          {items.map((item, index) => (
            <div key={index} className="personal-collection-div" onClick={() => goToDigital(item.itemUrl)}>
              <div className="popularCardTopDiv">
                <img
                  className="popularProfilePic"
                  src={`${url_link}images/${userAuthenticated}.png`}
                  onError={(e) => {
                    if (e.target.src !== profile_icon) {
                      e.target.onerror = null;
                      e.target.src = profile_icon;
                    }
                  }}
                ></img>
                <p className="popularProfileUsername">
                  {item.username}
                </p>
              </div>
              <img
                  className="collectionItemImage"
                  src={`${url_link}transaction/digitalImg/${item.timestring}${userAuthenticated}.png`}
                  onError={(e) => {
                    if (e.target.src !== digital_icon) {
                      e.target.onerror = null;
                      e.target.src = digital_icon;
                    }
                  }}
                ></img>
              <div className="popularCardBottomDiv">
                <p className="popularLabel">{item.itemName}</p>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default PersonalCollection;
