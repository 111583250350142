import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import MinibarComponent from "./../Minibar.js";
import FooterComponent from "./../Footer.js";

import {
  getBalance,
  updateBalance,
  userAuthenticated,
} from "./../../extension/currentUser";
import { ItemBuyImage } from "./../../extension/setItemBuyIcon.js";
import { fetchImages } from "./../../extension/fetchItemImages.js";
import { calculateAverageRating } from "./../../extension/calculateAverageRating.js";

import BuyDialog from "./../../dialog/BuyDialog.js";
import CommentDialog from "../../dialog/itemCommentDialog.js";
import ItemBuyDialog from "./../../dialog/ItemDialog.js";
import QuantityDropdown from "./../../dropdown/QuantityDropdown.js";

import { convertSecondsToTime } from "./../../extension/SecondsToTime";

import {
  app_icon,
  soci_coin,
  delivery_icon,
  return_icon,
} from "./../../extension/exportedIcons";

import { cartArray } from "./../../data/cartArray";
import { configCart, addToCart } from "./functions/cartFunctions.js";

import {
  url_path,
  retrieveOneItemDigital,
  retrieveComment,
  copyrightLabel,
  stripePublishableKey,
  purchaseWithCoins,
  createOrder,
  paymentIntentPath,
  createPersonalDigital,
} from "./../../extension/exportedStrings";

import "./../../style/item-page.css";
import { fetchAudio } from "../../extension/fetchItemAudio.js";
import { addToNotification } from "../../data/notificationData.js";
import { generateUUID } from "../../extension/generateUUID.js";
import { calculateSociCoin } from "../../extension/socicoin_calculation.js";
import BuyChoiceDialog from "../../dialog/BuyChoiceDialog.js";

import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

import {
  addDigitalToPersonal,
  insertBuyToNotification,
  updateBuyDigitalStatus,
  updateBuyOrderStatus,
} from "../../data/reusuableBuyData.js";
import CheckoutFormDigital from "../CheckoutFormDigital.js";
import { convertPriceToStripe } from "../../extension/stripeConverter.js";

function DigitalPage() {
  const navigate = useNavigate();
  const location = useLocation();

  const [items, setItems] = useState([]);
  const [imageSrcs, setImageSrcs] = useState([]);
  const [price, setPrice] = useState(0);

  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [selectedQuantity, setSelectedQuantity] = useState(1);

  const [comments, setComments] = useState([]);
  const [commentRatnig, setCommentRating] = useState("");
  const [cartString, setCartString] = useState("Add To Cart");
  const [isOpen, setIsOpen] = useState(false);
  const [isBuyOpen, setBuyOpen] = useState(false);
  const [isCommentOpen, setIsCommentOpen] = useState(false);
  const [isCardOpen, setIsCardOpen] = useState(false);
  const [currentPath, setCurrentPath] = useState(
    location.pathname.split("/").pop()
  );
  const [prevPathname, setPrevPathname] = useState(location.pathname);

  const [musicTextTime, setMusicTextTime] = useState("0:00");
  const [durationString, setDurationString] = useState("0:00");

  const seekbar = document.getElementById("seekbar");

  const [audioFile, setAudioFile] = useState();
  const [audio, setAudio] = useState(new Audio());

  const [isPlaying, setIsPlaying] = useState(false);
  const [isLoading, setIsLoading] = useState([true]);
  const unique_id = generateUUID();

  const stripePromise = loadStripe(stripePublishableKey);
  const [clientSecret, setClientSecret] = useState("");

  const [convertedSociCoin, setConvertedSociCoin] = useState(0);
  const [priceSelected, setPriceSelected] = useState("");

  const [orderString, setOrderString] = useState("Order");
  const [isProcessing, setIsProcessing] = useState(false);

  const balance = getBalance();

  window.addEventListener("popstate", function (event) {
    setIsPlaying(false);
    audio.pause();
  });

  useEffect(() => {
    const retrieveItems = () => {
      const url = url_path + retrieveOneItemDigital;
      const data = {
        id: currentPath, // Replace with the actual username
      };

      fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      })
        .then((res) => {
          if (!res.ok) {
            throw new Error(`HTTP error! Status: ${res.status}`);
          }
          return res.json();
        })
        .then(async (data) => {
          setItems(data);
          const images = await fetchImages(
            data[0].timestring,
            data[0].username,
            data[0].type
          );

          setPrice(data[0].itemPrice);
          convertCoins(data[0].itemPrice);

          configureAudio(data[0].timestring, data[0].username);
          configCart(data[0], setCartString, cartArray);

          setImageSrcs(images);
          setIsLoading(false);
        })
        .catch((error) => {
          console.error("Error retrieving items:", error);
        });
    };

    const retrieveComments = () => {
      const url = url_path + retrieveComment;
      const data = {
        itemUrl: currentPath, // Replace with the actual username
      };

      fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      })
        .then((res) => {
          if (!res.ok) {
            throw new Error(`HTTP error! Status: ${res.status}`);
          }
          return res.json();
        })
        .then(async (data) => {
          const ratings = data.map((item) => parseFloat(item.rating));

          // Calculate the average rating
          const averageRating = calculateAverageRating(ratings);
          setCommentRating(averageRating);
          setComments(data);
        })
        .catch((error) => {
          console.error("Error retrieving items:", error);
        });
    };

    retrieveItems();
    retrieveComments();
  }, []);

  const convertCoins = (priceData) => {
    setConvertedSociCoin(calculateSociCoin(priceData));
  };

  const configureAudio = async (timestring, username) => {
    const audioItem = await fetchAudio(timestring, username);

    if (audioItem == null) {
      setMusicTextTime("Error: audio can't be found");
    } else {
      const storeAudio = new Audio();
      storeAudio.src = audioItem;
      storeAudio.addEventListener("loadedmetadata", () => {
        // Get the duration of the audio file
        setMusicTextTime(convertSecondsToTime(storeAudio.duration));
        setDurationString(convertSecondsToTime(storeAudio.duration));
      });
      setAudio(storeAudio);
    }
  };

  const openDialog = () => {
    setIsOpen(true);
    document.body.style.overflow = "hidden";
  };

  const openCommentDialog = () => {
    setIsCommentOpen(true);
    document.body.style.overflow = "hidden";
  };

  const goMessage = (value) => {
    setIsPlaying(false);
    audio.pause();

    let path = `/message/${value}`;
    navigate(path);
  };

  const playAudio = () => {
    if (isPlaying) {
      setIsPlaying(false);
      audio.pause();
    } else {
      setIsPlaying(true);
      audio.play();
    }
  };

  audio.addEventListener("timeupdate", () => {
    // Get the time remaining by subtracting the current time from the total duration
    const timeRemaining = audio.duration - audio.currentTime;
    // Update the time remaining in the format mm:ss
    setMusicTextTime(convertSecondsToTime(timeRemaining));
  });

  audio.addEventListener("ended", () => {
    // Audio has finished playing, you can perform any action here
    setMusicTextTime(durationString);
    setIsPlaying(false);
  });

  const getSeekbarStyle = () => {
    const percentage = (audio.currentTime / audio.duration) * 100;
    return {
      background: `linear-gradient(to right, #C3B1E1 ${percentage}%, #ddd ${percentage}%)`,
    };
  };

  const handleSeekbarChange = (event) => {
    const newTime = event.target.value;
    audio.currentTime = newTime;
  };

  const handleSeekStart = () => {
    if (isPlaying) {
      setIsPlaying(false);
      audio.pause();
    }
  };

  const handleSeekEnd = () => {
    if (!isPlaying) {
      setIsPlaying(true);
      audio.play();
    }
  };

  const addDigitalToPersonal = (type) => {
    setOrderString("Processing...");
    setIsProcessing(true);

    const url = url_path + createPersonalDigital;
    const data = {
      username: userAuthenticated, // Replace with the actual username
      DigitalName: items[0].itemName, // Replace with the actual password
      DigitalDescription: items[0].itemDescription,
      DigitalUrl: items[0].itemUrl,
      DigitalCategory: items[0].itemCategory,
      DigitalPrice: items[0].itemPrice,
      DigitalRating: "0",
      DigitalProof: items[0].itemProof,
      timestring: items[0].timestring,
    };

    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error(`HTTP error! Status: ${res.status}`);
        }
        return res.json();
      })
      .then((data) => {
        if (type == 1) {
          createPayment();
          setOrderString("Order");
          setIsProcessing(false);
        } else {
          purchaseWithSociCoin();
        }
      })
      .catch((error) => {
        setOrderString("Order");
        setIsProcessing(false);
      });
  };

  const purchaseWithSociCoin = () => {
    const url = url_path + purchaseWithCoins;

    const data = {
      username: userAuthenticated,
      price: convertedSociCoin,
    };

    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error(`HTTP error! Status: ${res.status}`);
        }
        return res.json();
      })
      .then(async (data) => {
        await Promise.all([
          updateBuyDigitalStatus(items[0].itemUrl),
          insertBuyToNotification(items),
        ]);

        setOrderString("Purchased");
        setIsProcessing(false);

        const newBalance =
          parseFloat(localStorage.getItem("balance")) - convertedSociCoin;
         updateBalance(newBalance);

        // goHome();
      })
      .catch((error) => {
        setOrderString("Order");
        setIsProcessing(false);
      });
  };

  const createPayment = () => {
    const url = url_path + paymentIntentPath;

    setPriceSelected(price);
    const updatedPrice = convertPriceToStripe(price);

    const data = {
      price: updatedPrice, // Replace with the actual price
    };

    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    }).then(async (result) => {
      var { clientToken } = await result.json();
      setClientSecret(clientToken);
      openCardDialog();
    });
  };

  const openBuyDialog = () => {
    setBuyOpen(true);
    document.body.style.overflow = "hidden";
  };

  const openCardDialog = () => {
    setIsCardOpen(true);
    document.body.style.overflow = "hidden";
  };

  const goHome = () => {
    const path = `/`;
    window.location.href = path;
  };

  return (
    <div>
      <MinibarComponent />
      {isLoading ? (
        <div className="spinner-container-page">
          <div className="spinner"></div>
        </div>
      ) : (
        <div className="itemBuyDiv">
          <div className="leftItemBuyDiv">
            <div className="buyImgContainer">
              {items && items[0] && (
                <img
                  src={imageSrcs[currentImageIndex]}
                  className={`buyImg`}
                  onClick={openDialog}
                />
              )}
            </div>

            <div className="buySeekbarDiv">
              <input
                className="digitalSeekbar"
                type="range"
                id="seekbar"
                min="0"
                max={audio.duration}
                value={audio.currentTime}
                onMouseDown={handleSeekStart}
                onMouseUp={handleSeekEnd}
                onChange={handleSeekbarChange}
                style={getSeekbarStyle()}
              />
            </div>

            <div className="digitalAudioDiv">
              <div className="childrenDigitalAudioDiv">
                <div className="addImageDiv">
                  <img
                    id="addMiddleImage"
                    className="addDigitalMiddleImage"
                    src={imageSrcs[currentImageIndex]}
                    alt="Digital Content"
                  />
                  <div id="addMusicDiv" className="digitalMusicDiv">
                    <div className="textMusicDiv">
                      <p className="textMusicLabel">{items[0].itemName}</p>
                      <p className="textMusicLabel2">{musicTextTime}</p>
                    </div>
                    <svg
                      className="addMusicRightIcon"
                      viewBox="0 0 16 16"
                      onClick={() => {
                        playAudio();
                      }}
                    >
                      {!isPlaying ? (
                        <path d="M11.596 8.697l-6.363 3.692c-.54.313-1.233-.066-1.233-.697V4.308c0-.63.692-1.01 1.233-.696l6.363 3.692a.802.802 0 0 1 0 1.393z" />
                      ) : (
                        <path d="M5.5 3.5A1.5 1.5 0 0 1 7 5v6a1.5 1.5 0 0 1-3 0V5a1.5 1.5 0 0 1 1.5-1.5zm5 0A1.5 1.5 0 0 1 12 5v6a1.5 1.5 0 0 1-3 0V5a1.5 1.5 0 0 1 1.5-1.5z" />
                      )}
                    </svg>
                  </div>
                </div>
              </div>
            </div>
            {comments == null || comments.length === 0 ? (
              <p className="ratingName">This listing has no ratings yet</p>
            ) : (
              <>
                <p className="ratingName">Average ratings: {commentRatnig}</p>
                <div className="ratingDiv">
                  <div className="ratingLabelsDiv">
                    <p className="ratingLeftLabel">Top comments</p>
                    <p className="ratingRightLabel" onClick={openCommentDialog}>
                      View all
                    </p>
                  </div>
                </div>
                <table className="ratingTable">
                  {comments.slice(0, 3).map((item, index) => (
                    <tr key={index}>
                      <td>
                        <div className="ratingLine">
                          <p className="buytableUsername">{item.username}</p>
                          <p className="buytableRating">{item.rating} ★</p>
                        </div>
                        <p className="buytableAdditional">{item.comment}</p>
                      </td>
                    </tr>
                  ))}
                </table>
              </>
            )}
          </div>
          <div className="rightItemBuyDiv">
            <p className="buyLabel">Name</p>
            <p className="buyName">{items[0].itemName}</p>
            <p className="buyLabel">Price</p>
            <div className="buyPriceDiv">
              <p className="buyPrice">${items[0].itemPrice}</p>
              <p className="buyOr">or</p>
              <img className="buyIconSociCoin" src={soci_coin} alt="Image" />
              <p className="buyPriceSociCoin">{convertedSociCoin}</p>
            </div>
            <p className="buyLabel">Description</p>
            <div className="buyDescriptionDiv">
              <textarea class="buyDescriptionText">
                {items[0].itemDescription}
              </textarea>
            </div>
            <p className="buyLabel">category</p>
            <p className="buyName">{items[0].itemCategory}</p>
            {items[0].username !== userAuthenticated && (
              <>
                <div className="buyDigitalShopDiv">
                  <button
                    className="buyNowCart"
                    onClick={() => {
                      if (isProcessing) return;

                      openBuyDialog();
                    }}
                  >
                    {orderString}
                  </button>
                  <div className="buyDeliveryDiv">
                    <div class="deliveryDiv">
                      <img
                        src={delivery_icon}
                        alt="Image"
                        class="deliveryImg"
                      ></img>
                      <p className="deliveryName">Digital Details</p>
                    </div>
                    <p className="deliveryDigitalLabel">
                      Once purchased, this digital item will be linked to your
                      account. Each time the song is used, your social media
                      presence will grow, boosting your recognition.
                    </p>
                  </div>
                  {/* <button className="buyMessageButton" onClick={() => {}}>Message Seller</button> */}
                </div>
                <button
                  className="buyDeliveryMessageButton"
                  onClick={() => {
                    goMessage(items[0].username);
                  }}
                >
                  Message Seller For Details
                </button>
              </>
            )}
          </div>
        </div>
      )}

      <div className="ProfileFooterDiv">
        <div className="footerView">
          <img className="footerImg" src={app_icon} alt="Image" />
          <FooterComponent />
        </div>

        <p className="copyrightLabel2">{copyrightLabel}</p>
      </div>

      <BuyDialog imageArray={imageSrcs} isOpen={isOpen} setIsOpen={setIsOpen} />
      <CommentDialog
        itemComments={comments}
        isOpen={isCommentOpen}
        setIsOpen={setIsCommentOpen}
      />
      <BuyChoiceDialog
        isOpen={isBuyOpen}
        setIsOpen={setBuyOpen}
        price={price}
        itemData={addDigitalToPersonal}
      />

      {clientSecret !== "" && isCardOpen && (
        <Elements stripe={stripePromise} options={{ clientSecret }}>
          <CheckoutFormDigital
            price={priceSelected}
            isOpen={isCardOpen}
            setIsOpen={setIsCardOpen}
            unique_id={unique_id}
            orders={items}
          />
        </Elements>
      )}
    </div>
  );
}

export default DigitalPage;
