/** @format */

import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";

import { url_path, updateOrder } from "./../extension/exportedStrings";
import "./../style/update-sale.css";
import OrderStatusDropdown from "../extension/orderStatusDropdown";
import { getStatusFromText, getTextFromStatus } from "../extension/orderStatusConfiguration";

const UpdateSale = ({
  isOpen,
  setIsOpen,
  orderID,
  trackingLabel,
  orderSelected,
  orderArray,
  setOrderArray,
}) => {
  const updateRef = useRef(null);
  const [selectedStatus, setSelectedStatus] = useState(1);
  const [selectedStatusLabel, setSelectedStatusLabel] = useState('Order Placed - Your order has been successfully submitted and confirmed.')
  const [trackingInputValue, setTrackingInputValue] = useState(''); // Initialize with trackingLabel
  const [buttonChange, setbuttonChange] = useState("Confirm Change");

  const handleInputChange = (event) => {
    setTrackingInputValue(event.target.value); // Update state with the new input value
  };


  useEffect(() => {
    const handleClickOutside = (event) => {
      if (updateRef.current && !updateRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen, setIsOpen, updateRef]);

  useEffect(() => {
    if (isOpen) {
      setTrackingInputValue("");
    }
  }, [isOpen]);

  useEffect(() => {
    setSelectedStatus(getStatusFromText(orderSelected.delivery));
    setSelectedStatusLabel(getTextFromStatus(getStatusFromText(orderSelected.delivery)));
  }, [orderSelected.delivery]); // This effect will run whenever orderSelected.delivery changes


  const confirmData = () => {
    var trackingDataValue = trackingInputValue.trim() === "" ? orderSelected.tracking : trackingInputValue;

    const url = url_path + updateOrder;
    const data = {
      id: orderID,
      delivery: selectedStatusLabel,  
      tracking: trackingDataValue,  
    };

    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error(`HTTP error! Status: ${res.status}`);
        }
        return res.json();
      })
      .then((data) => {
        orderSelected.delivery = selectedStatusLabel;
        orderSelected.tracking = trackingDataValue;
      
        // Update the order array
        const updatedOrders = [...orderArray]; // Create a copy of the current orders
        const index = updatedOrders.findIndex((order) => order.id === orderSelected.id);
      
        if (index !== -1) {
          updatedOrders[index] = { ...orderSelected }; // Update the specific order
          setOrderArray(updatedOrders); // Update the state
        }

         setbuttonChange("Confirm Change")
         setIsOpen(false);
      })
      .catch((error) => {
          console.log(error);
      });
  };
 
  return (
    <div>
      <div className={isOpen ? "darkenDiv" : "hidden"}>
        <div className="saleDivBox" ref={updateRef}>
          <p className="updateTitle">Order #{orderID} - Update</p>
          <p className="updateLabel">Delivery Status</p>
          <OrderStatusDropdown
                  cName={"buyStatusChange"}
                  selectedStatus={selectedStatus}
                  setSelectedStatus={setSelectedStatus}
                  setSelectedStatusLabel={setSelectedStatusLabel}
                />
          <p className="updateLabel">Tracking Link</p>
          <input
            id="input2"
            className="updateInput"
            type="text"
            name="username"
            placeholder={trackingLabel === "-" ? "Enter order delivery link" : trackingLabel}
            value={trackingInputValue}
            onChange={handleInputChange} // Track changes in the input
            onKeyDown={(event) => {
              if (event.keyCode === 13) confirmData(); // Pass the current input value when Enter is pressed
            }}
          />
          <button
            id="loginButton"
            className="updateButton"
            onClick={() => {
              if(buttonChange === "Processing...") return;
              setbuttonChange("Processing...")
              confirmData();
            }}
          >
            {buttonChange}
          </button>
        </div>
      </div>
    </div>
  );
};

export default UpdateSale;
