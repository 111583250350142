const authenticationData = JSON.parse(localStorage.getItem('currentUser'));
export const userAuthenticated = authenticationData && authenticationData.username;

export function getBalance() {
  const balance = localStorage.getItem('balance');
    
  // Check if balance is not null, and convert it back to a number
  if (balance !== null) {
    return parseFloat(balance); // Convert the string to a float
  }
  
  // If no balance is found, return 0
  return 0;
}

export function updateBalance(newBalance) {
  // Convert the new balance to a string (if necessary)
  const balanceString = newBalance.toString();

  // Update the balance in localStorage
  localStorage.setItem('balance', balanceString);
}

  