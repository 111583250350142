import React, { useState } from 'react';

const QuantityDropdown =  ({ cName, selectedQuantity, setSelectedQuantity }) => {
  
  const handleQuantityChange = (event) => {
    setSelectedQuantity(parseInt(event.target.value));
  };

  return (
    <select
      className={cName}
      value={selectedQuantity}
      onChange={handleQuantityChange}
    >
      <option value={1}>Alternative</option>
      <option value={2}>Blues</option>
      <option value={3}>Classical</option>
      <option value={4}>Country</option>
      <option value={5}>Electronic</option>
      <option value={6}>Hip Hop/Rap</option>
      <option value={7}>Jazz</option>
      <option value={8}>Pop</option>
      <option value={9}>R&B/Soul</option>
      <option value={10}>Reggae</option>
      <option value={11}>Rock</option>
      <option value={12}>World</option>
      <option value={13}>Other</option>
    </select>
  );
};

export default QuantityDropdown;
