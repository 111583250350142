/** @format */
import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import MinibarComponent from "./Minibar.js";
import FooterComponent from "./Footer.js";

import { app_icon2, profile_icon } from "./../extension/exportedIcons";
import { app_icon, soci_coin } from "./../extension/exportedIcons";
import { copyrightLabel, retrieveItemNotification, url_link, url_path } from "./../extension/exportedStrings";

import "./../style/notification.css";
import { userAuthenticated } from "../extension/currentUser.js";

const Notifcation = () => {
  const navigate = useNavigate();

  const [items, setItems] = useState([]);
  const [isLoading, setIsLoading] = useState([true]);

  useEffect(() => {
    document.title = "SociRank - Notification"; // Change the title here

    retrieveItems();
  }, []);

  const retrieveItems = () => {
    const url = url_path + retrieveItemNotification;
    const data = {
      username: userAuthenticated, // Replace with the actual username
    };

    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error(`HTTP error! Status: ${res.status}`);
        }
        return res.json();
      })
      .then((data) => {
        setItems(data);
        setIsLoading(false); 
      })
      .catch((error) => {
        console.error("Error retrieving items:", error);
        setIsLoading(false); 
      });
  };

  const goProfile = (value) => {
      let path = `/${value}`;
      navigate(path);
  };

  const goMessage = (value) => {
    let path = `/message/${value}`;
    navigate(path);
  };

  return (
    <div>
        <MinibarComponent />

        <div className="notificationDiv">
        {isLoading ? (
              <div className="spinner-container">
                <div className="spinner"></div>
              </div>
            ) : (
              items.length === 0 ? (
                <p className="noDataLabel">You currently have no notifications</p>
              ) : (
                items.map((item, index) => (
                  <div className="notificationTable" key={index}>
                    <img
                        className="notifIcon" width="40" height="40"
                        src={`${url_link}images/${item.username}.png`}
                        onError={(e) => {
                          if (e.target.src !== profile_icon) {
                            e.target.onerror = null;
                            e.target.src = profile_icon;
                          }
                        }}
                        onClick={() => {
                          goProfile(item.username);
                        }}
                    ></img>
                    <div className="notifContent">
                      <div className="notifInfoDiv">
                        <p className="notifDivLabel" onClick={() => {
                          goProfile(item.username);
                        }}>{item.username}</p>
                        <p className="notifDivLabel2">{item.notification_text}</p>
                      </div>
                      <button className="notifButton" onClick={() => {goMessage(item.username)}}>Send Message</button> {/* Button on the right */}
                    </div>
                  </div>
                ))
              )
            )}
        </div>

        <div className="footerDiv2">
        <div className="footerView">
          <img
            className="footerImg"
            src={app_icon}
            alt="Image"
          />
          <FooterComponent />
        </div>

          <p className="copyrightLabel">
            {copyrightLabel}
          </p>
        </div>
    </div>
  );
};

export default Notifcation;
