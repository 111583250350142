/** @format */

import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import { app_icon, app_icon2 } from "./../extension/exportedIcons";

import { emailCheck } from "./../data/checkEmail";

import {
    url_path,
    login,
    googleClientID,
    googleLoginSecretKey,
    copyrightLabel,
  } from "./../extension/exportedStrings";

import "./../style/loginDialog.css";

const LoginDialog = ({ isOpen, setIsOpen, setIsExtra, value}) => {
  const navigate = useNavigate();

  const [button, setButton] = useState("Log in");
  const [isButtonDisabled, setButtonDisabled] = useState(false);

  const handleSubmit = async () => {
    const username = document.getElementById("username");
    const password = document.getElementById("password");

    if (username.value.trim() == "") {
      username.style.borderColor = "red";
      password.style.borderColor = "#e3e3e3";
      return;
    } else if (password.value.trim() == "") {
      username.style.borderColor = "#e3e3e3";
      password.style.borderColor = "red";

      return;
    }
    setButton("Processing...");

    username.style.borderColor = "#e3e3e3";
    password.style.borderColor = "#e3e3e3";

    setButtonDisabled(true);

    const url = url_path + login;

    const usernameVal = username.value;
    const passwordVal = password.value;
    const data = {
      username: usernameVal, // Replace with the actual username
      password: passwordVal, // Replace with the actual password
    };

    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error(`HTTP error! Status: ${res.status}`);
        }
        return res.json();
      })
      .then((data) => {
        // Handle the response data here
        if (data == "success") {
          // Assuming you have received authentication data like a token or username
          const authenticationData = {
            username: usernameVal,
          };

          // Save the data to localStorage
          localStorage.setItem(
            "currentUser",
            JSON.stringify(authenticationData)
          );

          let path = `/confirm-application/${value}`;
          navigate(path);
        } else {
          setButton("Log in");
          setButtonDisabled(false);
          username.style.borderColor = "red";
          password.style.borderColor = "red";
        }
      })
      .catch((error) => {
        setButton("Log in");
        setButtonDisabled(false);
        // Handle any errors that occurred during the fetch
        console.error("Fetch error:", error);
      });
  };

  const goRegister = () => {
    setIsOpen(false)
    setIsExtra(true)
  };

  const goForgot = () => {
    let path = `/forgot-password`;
    navigate(path);
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      // Handle the Enter key press here
      handleSubmit();
    }
  };

  return (
    <div>
       <div className={isOpen ? "darkenDiv" : "hidden"}>
        <div className="divBox">
          <img src={app_icon2} className="divImage" width="25" height="25" />
          <p className="divLoginLabel">You need an account to proceed with the application</p>
          <input
            className="divInputInput"
            id="username"
            name="username"
            placeholder="Enter username"
            onKeyPress={handleKeyPress}
            autoFocus
          ></input>
          <div class="password-container">
            <input
              id="password"
              class="loginPassword"
              name="password"
              type="password"
              placeholder="Enter password"
              onKeyPress={handleKeyPress}
            />
            <svg id="toggleEye" fill="currentColor" class="hidden">
              <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z" />
              <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z" />
            </svg>
          </div>

          <p id="divForgot" class="divForgot" onClick={goForgot}>
            Forgot password?
          </p>
          <button
            class="divButton"
            disabled={isButtonDisabled}
            onClick={handleSubmit}
          >
            {button}
          </button>
          <p id="loginRegister" class="loginRegister" onClick={goRegister}>
            Create an account
          </p>
        </div>
      </div>
    </div>
  );
}

export default LoginDialog;
