export function getCurrentTimeInMilliseconds() {
    return Date.now();
}
  
export const uploadItemNames = (imageNames, timestamp, username) => {
    const imgArrayNames = [];
  
    // Iterate over each image URL
    imageNames.forEach((imageName, index) => {
      imgArrayNames[index] = `${timestamp}${username}${index}`;
    });
  
    return imgArrayNames;
};
  