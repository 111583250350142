import { url_link } from "./exportedStrings";

export const fetchAudio = async (timestring, currentUser) => {
    try {
      const basePath = "transaction/digitalAudio/";
      const value = encodeURIComponent(`${timestring}${currentUser}`);
      const audioUrl = `${url_link}${basePath}${value}.mp3`;

      return audioUrl;
    } catch (error) {
      console.error("Failed to retrieve audio URL:", error);
      return null; // Handle the failure case
    }
};
  