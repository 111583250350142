/** @format */

import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import QuantityDropdown from "./../extension/quantityDropdown";
import MinibarComponent from "./../design/Minibar.js";
import FooterComponent from "./../design/Footer.js";

import { categoryArray } from "./../data/categoryArray";
import { uploadItemImg } from "./../extension/uploadItemImg";

import { userAuthenticated } from "./../extension/currentUser";

import {
  getCurrentTimeInMilliseconds,
  uploadItemNames,
} from "./../extension/getMilliseconds";

import { generateUUID } from "./../extension/generateUUID";
import { openGallery } from "./../extension/openGallery";
import { app_icon } from "./../extension/exportedIcons";
import {
  url_path,
  createItem,
  itemImage,
  copyrightLabel,
} from "./../extension/exportedStrings";

import "./../style/profile_item_goods.css";
import { calculateFees } from "../extension/feesRate.js";

function ProfileItemGoods() {
  const navigate = useNavigate();

  useEffect(() => {
    document.body.style.overflowY = "auto";
  }, []);

  const [selectedQuantity, setSelectedQuantity] = useState(1);
  const [imgsArray, setImgsArray] = useState([]);
  const [imgIndex, setImgIndex] = useState(0);
  const [currentImage, setCurrentImage] = useState(null);

  const [buttonText, setButtonText] = useState("Publish");
  const [buttonEnabled, setButtonEnabled] = useState(true);

  const [price, setPrice] = useState(""); // Initialize state for price

  const handlePriceChange = (e) => {
    setPrice(e.target.value); // Update state with the input value
  };

  const handleArrowClick = (direction, index) => {
    let newIndex;
    if (direction === "left") {
      newIndex = (imgIndex - 1 + imgsArray.length) % imgsArray.length;
    } else {
      newIndex = (imgIndex + 1) % imgsArray.length;
    }
    setCurrentImage(imgsArray[newIndex]);
    setImgIndex(newIndex);
  };

  const handleClick = (index) => {
    setCurrentImage(imgsArray[index]);
    setImgIndex(index);
  };

  const handleImage = (imageDataUrl) => {
    // Handle the image data URL here, for example, set it to state
    setImgsArray((prevImgsArray) => [...prevImgsArray, imageDataUrl]);
    setCurrentImage(imageDataUrl);
  };

  const submitItem = async () => {
    const middleImage = document.getElementById("addMiddleImage");
    const itemID = document.getElementById("addItemID");
    const itemDescription = document.getElementById("addItemDescription");
    const itemCategory = document.getElementById("itemCategory");
    const itemPrice = document.getElementById("addItemPrice");

    if (imgsArray.length == 0) {
      middleImage.style.border = "1px solid #E30B5C";
      return;
    }
    middleImage.style.border = "1px solid #e3e3e3e3";
    if (itemID.value.trim() == "") {
      itemID.style.border = "1px solid #E30B5C";
      return;
    }
    itemID.style.border = "1px solid #e3e3e3e3";

    if (itemDescription.value.trim() == "") {
      itemDescription.style.border = "1px solid #E30B5C";
      return;
    }
    itemDescription.style.border = "1px solid #e3e3e3e3";

    if (itemPrice.value.trim() == "") {
      itemPrice.style.border = "1px solid #E30B5C";
      return;
    }
    itemPrice.style.border = "1px solid #e3e3e3e3";

    try {
      setButtonText("Publishing...");
      setButtonEnabled(false);
      const timestamp = getCurrentTimeInMilliseconds(); // Use const instead of let for immutable variables
      const name = itemID.value.trim();
      const description = itemDescription.value.trim();
      const url = generateUUID();
      const category = categoryArray[selectedQuantity];
      const price = itemPrice.value.trim();

      uploadItemImg(imgsArray).then((resizedUrls) => {
        const nameArray = uploadItemNames(
          imgsArray,
          timestamp,
          userAuthenticated
        );
        uploadImagesToBackend(
          resizedUrls,
          nameArray,
          name,
          description,
          url,
          category,
          price,
          timestamp
        );
      });
    } catch (error) {
      setButtonText("Try Again");
      setButtonEnabled(true);
      console.error("Error submitting item:", error);
    }
  };

  const uploadImagesToBackend = async (
    imageArray,
    nameArray,
    name,
    description,
    itemUrl,
    category,
    price,
    timestamp
  ) => {
    const url = url_path + itemImage;
    const data = {
      imageArray: imageArray, // Replace with the actual username
      nameArray: nameArray, // Replace with the actual password
    };

    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error(`HTTP error! Status: ${res.status}`);
        }
        return res.json();
      })
      .then((data) => {
        uploadItemsToDatabase(
          name,
          description,
          itemUrl,
          category,
          price,
          timestamp
        );
      })
      .catch((error) => {
        // Handle any errors that occurred during the fetch
        setButtonText("Try Again");
        setButtonEnabled(true);
      });
  };

  const uploadItemsToDatabase = async (
    itemName,
    itemDescription,
    itemUrl,
    itemCategory,
    itemPrice,
    itemTimeString
  ) => {
    const url = url_path + createItem;
    const data = {
      username: userAuthenticated, // Replace with the actual username
      itemName: itemName, // Replace with the actual password
      itemDescription: itemDescription,
      itemUrl: itemUrl,
      itemCategory: itemCategory,
      itemPrice: itemPrice,
      itemQuantity: "0",
      itemRating: "0",
      timestring: itemTimeString,
    };

    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error(`HTTP error! Status: ${res.status}`);
        }
        return res.json();
      })
      .then((data) => {
        let path = `/${userAuthenticated}`;
        navigate(path);
      })
      .catch((error) => {
        // Handle any errors that occurred during the fetch
        setButtonText("Try Again");
        setButtonEnabled(true);
      });
  };

  return (
    <div>
      <MinibarComponent />
      <div className="addItemDiv">
        <div className="addLeftDiv">
          <div className="addImageDiv">
            <svg
              class="addLeftIcon"
              viewBox="0 0 16 16"
              onClick={() => handleArrowClick("left", imgIndex)}
            >
              <path d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0zm3.5 7.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5z" />
            </svg>

            <img
              id="addMiddleImage"
              className="addMiddleImage"
              src={currentImage}
            />
            <svg
              class="addRightIcon"
              viewBox="0 0 16 16"
              onClick={() => handleArrowClick("right", imgIndex)}
            >
              <path d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z" />
            </svg>
          </div>
          <div className="add-item-container">
            {imgsArray.length < 5 && (
              <svg
                className="addItemicon"
                viewBox="0 0 448 512"
                onClick={() => {
                  openGallery()
                    .then((file) => {
                      const reader = new FileReader();
                      reader.readAsDataURL(file);
                      reader.onload = () => {
                        const imageDataUrl = reader.result;
                        // Call the handler function with the image data URL
                        handleImage(imageDataUrl);
                      };
                    })
                    .catch((error) => {
                      console.error("Error:", error);
                    });
                }}
              >
                <path d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z" />
              </svg>
            )}

            {imgsArray.map((imageDataUrl, index) => (
              <img
                key={index} // Ensure each image has a unique key
                className={`addItemimage ${
                  index === imgIndex ? "selectedImg" : ""
                }`}
                src={imageDataUrl}
                alt={`Image ${index + 1}`}
                onClick={() => handleClick(index)}
              />
            ))}
          </div>
        </div>
        <div className="addRightDiv">
          <div className="addRightItem">
            <p className="addItemLabel">Item Name</p>
            <input
              id="addItemID"
              className="addItemInput"
              placeholder="Enter item name"
              autoFocus
            ></input>
          </div>
          <div className="addRightItem">
            <p className="addItemLabel">Item Description</p>
            <textarea
              id="addItemDescription"
              className="addItemDescription"
              placeholder="Enter item description"
              autoFocus
            ></textarea>
          </div>

          <div className="addRightItem">
            <p className="addItemLabel">Category</p>
            <QuantityDropdown
              id={"itemCategory"}
              cName={"itemQuantity"}
              selectedQuantity={selectedQuantity}
              setSelectedQuantity={setSelectedQuantity}
            />
          </div>
          <div className="addRightItem">
            <p className="addItemLabel">Set Price (USD)</p>
            <input
              id={"addItemPrice"}
              className="addItemInput"
              type="number"
              placeholder="Enter amount (e.g., 5000)"
              value={price} // Bind the state to the input's value
              onChange={handlePriceChange} // Handle changes
              autoFocus
            ></input>
          </div>
          <p className="feesLabel">{calculateFees(price)}</p>
          <button
            className="itemButton"
            onClick={submitItem}
            disabled={!buttonEnabled}
          >
            {buttonText}
          </button>
        </div>
      </div>

      <div className="settingFooterDiv">
        <div className="footerView">
          <img className="footerImg" src={app_icon} alt="Image" />
          <FooterComponent />
        </div>
        <p className="copyrightLabel2">{copyrightLabel}</p>
      </div>
    </div>
  );
}

export default ProfileItemGoods;
