import { database, ref, set, onValue, remove, storage, downloadUrl, bytes, uploadBytesResumable, sRef } from './firebase';

export const addVideoToStorage = async (selectedFile, name) => {
    // change it to name user specifies
    const storageRef = sRef(storage, 'videos/' + name);
    
    // Create a new upload task
    const uploadTask = uploadBytesResumable(storageRef, selectedFile);
  
    return new Promise((resolve, reject) => {
      uploadTask.on(
        'state_changed',
        (snapshot) => {
          // Handle progress, if needed
        },
        (error) => {
          console.error('Error uploading video:', error);
          reject(error); // Reject the promise on error
        },
        () => {
          // Upload completed successfully, get download URL
          downloadUrl(storageRef)
            .then((url) => {
              resolve(url); // Resolve the promise with the download URL
            })
            .catch((error) => {
              console.error('Error getting download URL:', error);
              reject(error); // Reject the promise on error
            });
        }
      );
    });
  };
