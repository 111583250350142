import { url_path, createItemNotification } from "../extension/exportedStrings";

export const addToNotification = (item, username, notification_text) => {
    const url = url_path + createItemNotification; // Ensure `url_path` and `createItemNotification` are defined
    const data = {
      username: username, // Use the passed username
      item_username: item.username, // Ensure `item` has a valid `username` property
      notification_text: notification_text, // Include notification text in the data
      item_url: item.itemUrl,
      timestring: new Date().toISOString() // Use the correct JavaScript Date method
    };
  
    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json", // Specify JSON content type
      },
      body: JSON.stringify(data), // Convert the `data` object to a JSON string
    })
      .then((res) => {
        if (!res.ok) {
          // If the response status is not OK, throw an error
          throw new Error(`HTTP error! Status: ${res.status}`);
        }
        return res.json(); // Parse the response as JSON
      })
      .then((data) => {

      })
      .catch((error) => {
      });
  };
  