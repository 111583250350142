/** @format */

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import FooterComponent from "./../design/Footer.js";
import { userAuthenticated } from "./../extension/currentUser";
import { app_icon } from "./../extension/exportedIcons";
import { url_path, insertAddress, copyrightLabel } from "./../extension/exportedStrings";
import "./../style/address.css";
import { fetchAddress } from "../data/retrieveAddress.js";

const Address = () => {
  const navigate = useNavigate();
  const [deliveryAddress, setDeliveryAddress] = useState("Enter your address");
  const [inputAddress, setInputAddress] = useState("");

  useEffect(() => {
    const updateAddress = async () => {
      const address = await fetchAddress(); // Wait for the address to be fetched
      setDeliveryAddress(address); // Set the fetched address
    };
    
    updateAddress();
  }, []);

  const confirmAddress = (address) => {
    const url = url_path + insertAddress;
    const data = {
      username: userAuthenticated,
      address: address, 
    };
  
    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error(`HTTP error! Status: ${res.status}`);
        }
        return res.json();
      })
      .then((data) => {
        console.log("Address updated successfully:", data);
      })
      .catch((error) => {
        console.error("Error updating address:", error);
      });
    navigate(-1); // Go back to the previous page
  };

  return (
    <div>
      <div className="addressBox">
        <p className="addressTitle">Update delivery address</p>
        <input
          id="text"
          className="addressInput"
          type="text"
          name="text"
          placeholder={deliveryAddress}
          value={inputAddress}
          onChange={(e) => setInputAddress(e.target.value)} // Update the input state
          onKeyDown={(event) => {
            if (event.keyCode === 13) confirmAddress(inputAddress); // Call confirmAddress on Enter key
          }}
        />

        <button
          id="addressButton"
          className="addressButton"
          onClick={() => confirmAddress(inputAddress)} // Pass the input value on button click
        >
          Update address
        </button>
      </div>

      <div className="loginFooterDiv">
        <div className="footerView">
          <img className="footerImg" src={app_icon} alt="App icon" />
          <FooterComponent />
        </div>

        <p className="copyrightLabel">{copyrightLabel}</p>
      </div>
    </div>
  );
};

export default Address;
