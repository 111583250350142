/** @format */
import React, { useEffect, useState } from "react";

import "./../../style/coming-soon.css";

const ComingSoon = ({}) => {
  return (
    <div>
        <div className="comingSoonDiv">
           <p className="comingSoonLabel">Digital features coming soon.</p>
        </div>
    </div>
  );
};

export default ComingSoon;
