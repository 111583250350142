/** @format */
import React, { useEffect, useRef } from "react";

import "./../style/tracking-safety-dialog.css";
import { error_icon } from "../extension/exportedIcons";

const TrackingSafetyDialog = ({ isOpen, setIsOpen, trackingValue }) => {
  const dialogRef = useRef(null);
  
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dialogRef.current && !dialogRef.current.contains(event.target)) {
        closeDialog();
      }
    };

    const handleKeyDown = (event) => {
      if (event.key === "Escape") {
        closeDialog();
      }
    };

    const closeDialog = () => {
      setIsOpen(false);
      document.body.style.overflowY = "auto";
    };

    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
      document.addEventListener("keydown", handleKeyDown);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [isOpen]);

  const openLink = () => {
    window.open(trackingValue, "_blank");
  };

  return (
    <div className={isOpen ? "darkenDiv" : "hidden"}>
      <div className={isOpen ? "safetyDialogDiv" : "hidden"} ref={dialogRef}>
        <p className="safetyDivTitle">Please verify that the order tracking link you are about to click is legitimate and not potentially malicious.</p>
        <p className="safetyDivLabel" onClick={openLink}>{trackingValue}</p>
      </div>
    </div>
  );
};

export default TrackingSafetyDialog;
