import React, { useState } from "react";

import "./../style/full-screen-dialog.css";

const FullScreenImageDialog = ({ isOpen, setIsOpen, currentImage }) => {

  const closeDialog = () => {
    setIsOpen(false);
    document.body.style.overflow = "auto";
  };

  return (
    <div>
      <div className={isOpen ? "darkenDiv" : "hidden"} onClick={closeDialog}>
        <div className="fullScreenDiv">
        <img
          className="fullScreenImg"
          src={currentImage}
          onClick={(event) => {
            event.stopPropagation(); // Prevent click event propagation
          }}
        ></img>

        <svg class="dialogCross" viewBox="0 0 16 16" onClick={closeDialog}>
          <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z" />
        </svg>
        </div>
      </div>
    </div>
  );
};

export default FullScreenImageDialog;
