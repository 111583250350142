/** @format */
import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import { userAuthenticated } from "./../extension/currentUser";

import "./../style/settings.css";
const Settings = ({image,isOpen, setIsOpen, profileDivRef}) => {
  const navigate = useNavigate();
  const location = useLocation();
  
  const settingsDivRef = useRef(null);

  useEffect(() => {
      const handleClickOutside = (event) => {
        if (
          (profileDivRef.current && !profileDivRef.current.contains(event.target)) &&
          (settingsDivRef.current && !settingsDivRef.current.contains(event.target))
        ) {
          setIsOpen(false);
        }
      };
      
      const handleKeyDown = (event) => {
        if (event.key === "Escape") {
          closeDialog();
        }
      };
  
      const closeDialog = () => {
        setIsOpen(false);
      };
  
      if (isOpen) {
        document.addEventListener("mousedown", handleClickOutside);
        document.addEventListener("keydown", handleKeyDown);
      }
  
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
        document.removeEventListener("keydown", handleKeyDown);
      };
      
  }, [isOpen]);

  const logOffUser = () => {
    localStorage.removeItem("currentUser");
    
    let path = `/login`;
    navigate(path, { replace: true });
  };

  const goSettings = () => {
    let path = `/setting`;
    navigate(path);
  };

  const goManage = () => {
    let path = `/manage`;
    navigate(path);
  };

  const goProfile = () => {
    const path = `/${userAuthenticated}`;
    if (location.pathname === path) {
      // Force a reload if already on the same path
      window.location.reload();
    } else {
      navigate(path);
    }
  };

  return (
    <div>
        <div className={isOpen ? "settingsDiv" : "hidden"} ref={settingsDivRef}>
          <div className="settingsDivView" onClick={goProfile}>
            <img src={image} className="settingsImg" width="25" height="25" />
            <p className="settingsLabel">{userAuthenticated}</p>
          </div>

          <div className="settingsDivView" onClick={goManage}>
          <svg width="25" height="25" class="settingsSvg" viewBox="0 0 16 16"> 
              <path d="M4 16s-1 0-1-1 1-4 5-4 5 3 5 4-1 1-1 1H4Zm4-5.95a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5Z"/> 
              <path d="M2 1a2 2 0 0 0-2 2v9.5A1.5 1.5 0 0 0 1.5 14h.653a5.373 5.373 0 0 1 1.066-2H1V3a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v9h-2.219c.554.654.89 1.373 1.066 2h.653a1.5 1.5 0 0 0 1.5-1.5V3a2 2 0 0 0-2-2H2Z"/> 
          </svg>
            <p className="settingsLabel">Manage</p>
          </div>
          
          <div className="settingsDivView" onClick={goSettings}>
            <svg  width="25" height="25" class="settingsSvg" viewBox="0 0 16 16"> 
            <path d="M9.405 1.05c-.413-1.4-2.397-1.4-2.81 0l-.1.34a1.464 1.464 0 0 1-2.105.872l-.31-.17c-1.283-.698-2.686.705-1.987 1.987l.169.311c.446.82.023 1.841-.872 2.105l-.34.1c-1.4.413-1.4 2.397 0 2.81l.34.1a1.464 1.464 0 0 1 .872 2.105l-.17.31c-.698 1.283.705 2.686 1.987 1.987l.311-.169a1.464 1.464 0 0 1 2.105.872l.1.34c.413 1.4 2.397 1.4 2.81 0l.1-.34a1.464 1.464 0 0 1 2.105-.872l.31.17c1.283.698 2.686-.705 1.987-1.987l-.169-.311a1.464 1.464 0 0 1 .872-2.105l.34-.1c1.4-.413 1.4-2.397 0-2.81l-.34-.1a1.464 1.464 0 0 1-.872-2.105l.17-.31c.698-1.283-.705-2.686-1.987-1.987l-.311.169a1.464 1.464 0 0 1-2.105-.872l-.1-.34zM8 10.93a2.929 2.929 0 1 1 0-5.86 2.929 2.929 0 0 1 0 5.858z"/> 
            </svg>
            <p className="settingsLabel">Settings</p>
          </div>
          <div className="settingsDivView" onClick={logOffUser}>
          <svg  width="25" height="25" class="settingsSvg" viewBox="0 0 16 16">
           <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/> 
           <path d="M9.146 5.146a.5.5 0 0 1 .708 0l.646.647.646-.647a.5.5 0 0 1 .708.708l-.647.646.647.646a.5.5 0 0 1-.708.708l-.646-.647-.646.647a.5.5 0 1 1-.708-.708l.647-.646-.647-.646a.5.5 0 0 1 0-.708zm-5 0a.5.5 0 0 1 .708 0l.646.647.646-.647a.5.5 0 1 1 .708.708l-.647.646.647.646a.5.5 0 1 1-.708.708L5.5 7.207l-.646.647a.5.5 0 1 1-.708-.708l.647-.646-.647-.646a.5.5 0 0 1 0-.708zM10 11a2 2 0 1 1-4 0 2 2 0 0 1 4 0z"/> 
           </svg>
            <p className="settingsLabel">Log off</p>
          </div>
       </div>
    </div>
  );
};

export default Settings;
