import { storage, sRef, uploadBytesResumable, downloadUrl } from './firebase';

export const addImageToStorage = async (selectedFile, name) => {
  // Define the file path as `image_proof`
  const storageRef = sRef(storage, 'image_proof/' + name);

  // Create a new upload task
  const uploadTask = uploadBytesResumable(storageRef, selectedFile);

  return new Promise((resolve, reject) => {
    uploadTask.on(
      'state_changed',
      (snapshot) => {
        // Handle upload progress if needed
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        // console.log('Upload is ' + progress + '% done');
      },
      (error) => {
        console.error('Error uploading image:', error);
        reject(error); // Reject the promise on error
      },
      () => {
        // Upload completed successfully, get the download URL
        downloadUrl(storageRef)
          .then((url) => {
            resolve(url); // Resolve the promise with the download URL
          })
          .catch((error) => {
            console.error('Error getting download URL:', error);
            reject(error); // Reject the promise on error
          });
      }
    );
  });
};
