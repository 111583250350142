/** @format */
import React, { useEffect, useState } from "react";

import { app_icon, app_icon2 } from "./../extension/exportedIcons";
import { copyrightLabel } from "./../extension/exportedStrings";

import "./../style/TermsOfService.css";
import FooterComponent from "./Footer.js";

const TermOfService = () => {
  useEffect(() => {
    document.title = "SociRank - Terms Of Use"; // Change the title here
  }, []);
  return (
    <div>
      <div className="tosBackground">
        <div className="tosHeader">
          <img src={app_icon2} className="Image" width="25" height="25" />
          <p className="tosHeaderLabel">Terms Of Use</p>
        </div>

        <div className="tosDiv">
          <p className="tosDivLabel">Understanding Our Data Practices</p>
          <p className="tosDivBody">
            At SociRank, transparency and clarity regarding your data are
            paramount. We believe that you should have a comprehensive
            understanding of how your data is collected, utilized, and how you
            can exercise control over it. To ensure this, we have provided
            extensive information in our Privacy Policy and Terms of Service.
            These documents delve into the specifics of our data practices and
            your rights. To offer you a more detailed perspective, here are some
            key highlights:
          </p>
          <p className="tosDivBody">
            <strong>Data Collection:</strong> Our platform collects specific
            types of data necessary for its functioning. This may include
            personal information, usage data, and more. We collect this data
            through various means, such as user input and automated processes.
          </p>
          <p className="tosDivBody">
            <strong>Data Utilization:</strong> Your data is used to enhance your
            experience on SociRank. This includes personalizing content,
            improving our services, and providing relevant recommendations. We
            take your privacy seriously and do not share your data with third
            parties without your consent.
          </p>
          <p className="tosDivBody">
            <strong>Data Control:</strong> We believe you should have control
            over your data. Our platform provides options for you to manage your
            data preferences. You can review, edit, or delete your information
            as needed.
          </p>
          <p className="tosDivBody">
            <strong>Security Measures:</strong>We have implemented robust
            security measures to protect your data from unauthorized access and
            breaches. Your privacy and data security are our top priorities.
          </p>
        </div>

        <div className="tosDiv">
          <p className="tosDivLabel">Personal Information</p>
          <p className="tosDivBody">
            Your personal information is valuable, and we want you to have full
            control over it. Until the time you decide to remove your account,
            rest assured that we'll securely store your data. This includes not
            only the videos you've shared, your profile photo, and any audio
            content you've uploaded – all of which are rightfully yours – but
            also the details necessary for our platform to function seamlessly.
            We take data privacy seriously, and you can find more details on how
            we handle and protect your information in our Privacy Policy.
          </p>
        </div>
        <div className="tosDiv">
          <p className="tosDivLabel">Payment and Purchase</p>
          <p className="tosDivBody">
            We prioritize the security of your financial information. When you add your payment details, rest assured that we do not store your credit card information in any database. This means that, even in the unlikely event of a security breach, your sensitive financial data remains inaccessible to any third party.
          </p>
          <p className="tosDivBody">
            We believe in fair and transparent transactions. If you wish to request a refund, we recommend reaching out to the seller directly to discuss their refund policy before making a purchase. Each seller may have different terms regarding refunds, so it's important to clarify this beforehand. If you are unable to reach the seller or if there is no response, please contact us for assistance.
          </p>
          <p className="tosDivBody">
            Please note that once an item is purchased, unless otherwise agreed upon with the seller, you are generally not eligible for a refund. We encourage you to ensure that all details about the item and the transaction are clear before completing the purchase.
          </p>
        </div>
        <div className="tosDiv">
          <p className="tosDivLabel">User Generated Content</p>
          <p className="tosDivBody">
            We have strict content guidelines in place to ensure a safe and
            respectful environment for all users. Our policies prohibit the
            sharing of content that involves violence, nudity, or any actions
            that infringe upon the privacy of others.
          </p>
          <p className="tosDivBody">
            Additionally, when it comes to sharing content that is not your own,
            we expect users to either obtain proper consent from the owner or
            provide appropriate credit to the original creator. Failure to
            adhere to these guidelines may result in the removal of
            non-compliant content.
          </p>
          <p className="tosDivBody">
            At SociRank, we take these rules seriously to maintain a positive
            and respectful community for everyone.
          </p>
        </div>
        <div className="tosDiv">
          <p className="tosDivLabel">Seller Guidelines</p>
          <p className="tosDivBody">
            At SociRank, we are committed to maintaining a safe, secure, and
            trustworthy environment for all users. Our platform facilitates
            online sales, and we expect sellers to adhere to the highest
            standards of conduct. Sellers must comply with our strict content
            and conduct policies, which include, but are not limited to,
            prohibitions against selling items or posting content that involves
            violence, explicit material, counterfeit goods, or any items that
            infringe upon the intellectual property or privacy rights of others.
          </p>
          <p className="tosDivBody">
            Sellers are required to only list products that they have the legal
            right to sell. When posting content that is not your own (e.g.,
            images, descriptions, or media), you must ensure that you have
            obtained proper consent from the owner or have provided appropriate
            credit to the original creator. All product descriptions and media
            must accurately reflect the item being sold, and any misleading or
            deceptive listings will be subject to removal.
          </p>
          <p className="tosDivBody">
            Violations of these guidelines will not be taken lightly. SociRank
            reserves the right to take action against any seller who fails to
            comply with these policies, including but not limited to suspending
            or permanently banning their account, removing non-compliant
            listings, and taking appropriate legal action when necessary. Our
            goal is to foster a positive, respectful, and lawful community for
            all users.
          </p>
          <p className="tosDivBody">
            We strongly encourage all sellers to familiarize themselves with our
            complete Terms of Service and adhere to the rules in order to
            maintain a safe and enjoyable experience for everyone. Failure to
            follow these guidelines could result in the removal of your content,
            the suspension of your account, or permanent banning from the
            platform.
          </p>
        </div>
        <div className="tosDiv">
          <p className="tosDivLabel">Buyer's Guidelines</p>
          <p className="tosDivBody">
            At SociRank, we prioritize the safety and satisfaction of all buyers
            on our platform. To ensure a positive and secure shopping
            experience, we expect buyers to adhere to the following guidelines:
          </p>
          <p className="tosDivBody">
            Buyers should only engage in transactions with trusted and verified
            sellers. It is important to carefully review product listings,
            including descriptions, images, and ratings, to ensure that the item
            meets your expectations before making a purchase. If you have any
            doubts or concerns about a product, we encourage you to reach out to
            the seller for more information or clarification.
          </p>
          <p className="tosDivBody">
            We also expect buyers to respect the rights and responsibilities of
            sellers. This means avoiding fraudulent or malicious activities,
            such as chargebacks, false claims, or attempting to deceive the
            seller. Any attempt to manipulate or exploit the platform for
            personal gain or to damage a seller's reputation will result in
            penalties, including suspension or banning from the platform.
          </p>
          <p className="tosDivBody">
            If you receive an item that does not match the description, is
            damaged, or does not meet your expectations, please contact the
            seller immediately to resolve the issue. SociRank also offers a
            buyer protection program for cases of non-delivery or misrepresented
            products. If the issue cannot be resolved directly with the seller,
            please report it to us, and we will investigate the matter promptly.
          </p>
          <p className="tosDivBody">
            In order to maintain fairness and trust within our marketplace,
            buyers are expected to leave honest reviews and ratings based on
            their purchase experience. Your feedback helps other buyers make
            informed decisions and ensures that high-quality sellers are
            recognized for their contributions.
          </p>
          <p className="tosDivBody">
            Violating the buyer's guidelines, such as engaging in dishonest
            practices, fraudulent transactions, or harassment, will not be
            tolerated. Consequences may include the suspension or removal of
            your account, as well as the revocation of your ability to make
            future purchases.
          </p>
          <p className="tosDivBody">
            We strongly encourage all buyers to familiarize themselves with our
            full Terms of Service to ensure that they are contributing to a
            positive and secure online shopping environment. By following these
            guidelines, you help us maintain a trustworthy platform for
            everyone.
          </p>
        </div>
        <div className="tosDiv">
          <p className="tosDivLabel">Fee Policy</p>
          <p className="tosDivBody">
            SociRank operates on a transparent fee structure to maintain platform services and provide a seamless experience for both buyers and sellers. Buyers are not directly charged service fees for browsing or purchasing items on the platform. However, the following conditions apply:
          </p>
          <p className="tosDivBody">
            SociRank Fee: Buyers will be charged an initial 5% platform fee on the total purchase price. This fee helps support the platform's operations, security measures, and continuous improvements to the user experience. The exact fee amount will be displayed during checkout.
          </p>
          <p className="tosDivBody">
            Shipping Fees: Buyers are responsible for paying shipping fees, which are calculated based on the seller's preferences, the item's weight, and delivery location. These charges will be clearly displayed during checkout.
          </p>
          <p className="tosDivBody">
            Payment Processing Fees: SociRank does not impose additional processing fees on buyers, but your payment provider (e.g., credit card company or PayPal) may apply standard transaction charges. Please refer to their terms for details.
          </p>
          <p className="tosDivBody">
            Customs or Import Fees: If you are purchasing an item internationally, customs or import taxes may apply based on your country’s regulations. These charges are separate from the product price and are not controlled by SociRank.
          </p>
          <p className="tosDivBody">
            Sellers on SociRank are charged referral fees and optional fulfillment service fees to help cover the costs of maintaining the platform. These fees are not passed directly to buyers but may be factored into the product pricing.
          </p>
          <p className="tosDivBody">
            By proceeding with purchases on SociRank, buyers acknowledge their responsibility for any additional fees as applicable. For further details, we encourage you to review our complete Fee Policy available in the Terms of Service.
          </p>
        </div>
      </div>
      <div className="tosFooterDiv">
        <div className="footerView">
          <img className="footerImg" src={app_icon} alt="Image" />
          <FooterComponent />
        </div>

        <p className="copyrightLabel2">{copyrightLabel}</p>
      </div>
    </div>
  );
};

export default TermOfService;
