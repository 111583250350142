/** @format */
import React, { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";

import { soci_coin, add_item_icon, add_digital_icon } from "./../extension/exportedIcons";
import "./../style/itemdialog.css";

const ItemDialog = ({ isOpen, setIsOpen }) => {
  const divRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (divRef.current && !divRef.current.contains(event.target)) {
        closeDialog();
      }
    };

    const handleKeyDown = (event) => {
      if (event.key === "Escape") {
        closeDialog();
      }
    };

    const closeDialog = () => {
      setIsOpen(false);
      document.body.style.overflowY = "auto";
    };

    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
      document.addEventListener("keydown", handleKeyDown);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [isOpen]);

  const goProductItem = () => {
    let path = `/add-product-item`;
    navigate(path);
  };

  const goDigitalItem = () => {
    let path = `/add-digital-item`;
    navigate(path);
  };

  return (
    <div className={isOpen ? "darkenDiv" : "hidden"}>
      <div className={isOpen ? "profileItemDiv" : "hidden"} ref={divRef}>
        <div className="profileMainDiv" onClick={goProductItem}>
            <div className="profileCardDiv">
              <img className="profileCardImage" src={add_item_icon} alt="Image" />
            </div>
            <p className="profileCardLabel">Add Personal Item</p>
        </div>
        <div className="profileMainDiv" onClick={goDigitalItem}>
            <div className="profileCardDiv">
              <img className="profileCardImage" src={add_digital_icon} alt="Image" />
            </div>
            <p className="profileCardLabel">Add Digital Item</p>
        </div>
      </div>
    </div>
  );
};

export default ItemDialog;
