export function calculateSociCoin(dollars) {
  // Remove any non-numeric characters (except for the decimal point)
  let cleanedAmount = dollars.toString().replace(/[^0-9.]/g, '');
  
  // Convert the cleaned string to a float number
  let amountInDollars = parseFloat(cleanedAmount);
  
  // If the amount is not a valid number, return 'undefined'
  if (isNaN(amountInDollars)) {
    return 'undefined';
  }
  
  // Convert to SociCoins and ensure a maximum of 2 decimal points
  let sociCoins = amountInDollars * 100;
  return parseFloat(sociCoins.toFixed(2));
}
