/** @format */
import React, { useEffect, useRef } from "react";

import "./../style/error-dialog.css";
import { error_icon } from "../extension/exportedIcons";

const ErrorDialog = ({ isOpen, setIsOpen, text }) => {
  const errorDivRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (errorDivRef.current && !errorDivRef.current.contains(event.target)) {
        closeDialog();
      }
    };

    const handleKeyDown = (event) => {
      if (event.key === "Escape") {
        closeDialog();
      }
    };

    const closeDialog = () => {
      setIsOpen(false);
      document.body.style.overflowY = "auto";
    };

    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
      document.addEventListener("keydown", handleKeyDown);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [isOpen]);

  return (
    <div className={isOpen ? "darkenDiv" : "hidden"}>
      <div className={isOpen ? "errorDialogDiv" : "hidden"} ref={errorDivRef}>
        <img src={error_icon} alt="Error" className="errorCenterImage" />
        <p className="errorDivLabel">{text}</p>
      </div>
    </div>
  );
};

export default ErrorDialog;
