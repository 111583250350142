/** @format */
import React, { useEffect, useRef } from "react";

const LoadingDialog = ({ isOpen, setIsOpen }) => {
  const divRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (divRef.current && !divRef.current.contains(event.target)) {
        closeDialog();
      }
    };

    const handleKeyDown = (event) => {
      if (event.key === "Escape") {
        closeDialog();
      }
    };

    const closeDialog = () => {
      setIsOpen(false);
      document.body.style.overflowY = "auto";
    };

    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
      document.addEventListener("keydown", handleKeyDown);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [isOpen]);

  return (
    <div className={isOpen ? "darkenDiv" : "hidden"}>
      <div className={isOpen ? "profileItemDiv" : "hidden"}>
        <div className="spinner-container">
          <div className="spinner"></div>
        </div>
      </div>
    </div>
  );
};

export default LoadingDialog;
