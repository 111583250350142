/** @format */
import { PaymentElement } from "@stripe/react-stripe-js";
import { useState } from "react";
import { useStripe, useElements } from "@stripe/react-stripe-js";

import "./../style/blurVideo.css";
import { updateStatus, url_path } from "../extension/exportedStrings";
import { userAuthenticated } from "../extension/currentUser";
import { addToNotification } from "../data/notificationData";
import { cartArray } from "../data/cartArray";
import { deleteSelectedCartItems } from "./items/functions/cartFunctions";
import { insertBuyToNotification, updateBuyOrderStatus } from "../data/reusuableBuyData";

const CheckoutFormDigital = ({ price, isOpen, setIsOpen, unique_id, orders }) => {

  const [message, setMessage] = useState(`Pay ${price}`);
  
  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      return;
    }
  
    const { error, paymentIntent } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: `${window.location.origin}/completion`, // Still redirect to a URL on success
      },
    });
  
    if (error) {
      // Handle card errors and validation errors
      if (error.type === "card_error" || error.type === "validation_error") {
        setMessage(error.message);
      } else {
        setMessage("An unexpected error occurred.");
      }
    } else {
      await Promise.all([
        updateBuyOrderStatus(orders[0].itemUrl),
        insertBuyToNotification(orders),
      ]);
    }
  };


  const closeDialog = () => {
    setIsOpen(false);
    document.body.style.overflow = "auto";
  };

  return (
    <div>
      <div className={isOpen ? "darkenDiv" : "hidden"} onClick={closeDialog}>
        <form className="payment-form">
          <p className="paymentLabel">Complete purchase of item</p>
          <PaymentElement className="payment-element"/>
          <button className="submit-button" onSubmit={handleSubmit}>{message}</button>
        </form>
      </div>
    </div>
  );
};

export default CheckoutFormDigital;
